<template>
    <div class="container">
        <div class="header">
            <text> 
                Action Required 
            </text>
            <div class="cross" @click="closePopup">
                <img src="@/assets/cross.svg" alt="cross"/>
            </div>
        </div>
        <div class="info-holder w-full flex gap-5 column main-container" >
            <div>
                <div class="line-label">Date</div>
                <div class="info-line">{{ renderTime(reviewAction.datetime) }} </div>
                <div class="line-label">Proposed by</div>
                <div class="info-line">{{ reviewAction.user_name }} </div>
            </div>
            <div v-if="currentFile">
                <p class="header-small">Account manager last comment since {{renderTime(currentFile.comment_data_handler_at)}}</p>

                <CommentSection 
                    attrId='comment_data_handler' 
                    class="markdown-content small"
                    :class="{disabled: true}"
                    :disabled="true"
                    :commentSection="currentFile.comment_data_handler"
                /> 
            </div>
            <div v-if="reviewAction.kyc_file && currentFile && reviewAction.actionType == 'remove' ">
                <div class=" w-cover current-version flex-1 flex column">
                    <h3 class="header-small">Current Version</h3>
                    <UploaderTable 
                        :disabled="true"
                        :key="`current-${currentFile.documentType}-${subsidiary_id}`"
                        :filePresent="currentFile.file"    
                        :headerText="getDocumentType"
                        :file="currentFile.file"  
                        :title="''"
                        :fileType="currentFile.fileType"
                        :index="currentFile._id"  
                        :fiscal_year_ending_on="currentFile.fiscal_year_ending_on"
                        :year_title="'year_title'"
                        :year="currentFile.year"
                        :subsidiary_id="currentFile.subsidiary_id"
                        :second_line_normal="'second_line_normal'"
                        :issueDateValue="currentFile.dateIssued"  
                        :label="'label'"
                        origin="kyc-section"
                        :fullWidth="true"
                        />
                </div>
            
            </div>
            <div class='flex gap-40 w-cover padding-20' v-else-if="reviewAction.kyc_file && currentFile">
                <div class="current-version flex-1 flex column">
                    <h3 class="header-small">Current Version</h3>
                    <UploaderTable 
                        :disabled="true"
                        :key="`current-${currentFile.documentType}-${subsidiary_id}`"
                        :filePresent="currentFile.file"    
                        :headerText="getDocumentType"
                        :file="currentFile.file"  
                        :title="''"
                        :fileType="currentFile.fileType"
                        :index="currentFile._id"  
                        :fiscal_year_ending_on="currentFile.fiscal_year_ending_on"
                        :year_title="'year_title'"
                        :year="currentFile.year"
                        :subsidiary_id="currentFile.subsidiary_id"
                        :second_line_normal="'second_line_normal'"
                        :issueDateValue="currentFile.dateIssued"  
                        :label="'label'"
                        origin="kyc-section"
                        :fullWidth="true"
                        />
                </div>
                <div class="proposed-changes flex-1">
                    <h3 class="header-small">Adjusted Version</h3>
                    <UploaderTable 
                        :disabled="true"
                        :key="`current-${currentFile.fileType}-${subsidiary_id}`"
                        :filePresent="JSON.parse(reviewAction.fileInfo)?.new"    
                        :headerText="getDocumentType"
                        
                        :file="JSON.parse(reviewAction.fileInfo)?.new"  
                        :title="title"
                        :fileType="currentFile.fileType"
                        :index="currentFile._id"  
                        :fiscal_year_ending_on="JSON.parse(reviewAction.fields)?.fiscal_year_ending_on?.new || currentFile.fiscal_year_ending_on"
                        :year_title="'year_title'"
                        :year="JSON.parse(reviewAction.fields)?.year?.new || currentFile.year"
                        :subsidiary_id="currentFile.subsidiary_id"
                        :second_line_normal="'second_line_normal'"
                        :issueDateValue="JSON.parse(reviewAction.fields)?.dateIssued?.new || currentFile.dateIssued"  
                        :label="'label'"
                        origin="kyc-section"
                        :fullWidth="true"
                        />
                </div>
            </div>
            <div class='flex gap-40 w-cover padding-20' v-else-if="reviewAction.person">
                <div class="current-version flex-1 flex column" v-if="curentPerson && reviewAction.actionType == 'remove'">
                    <PersonComp
                        v-if="curentPerson.ubo_related"
                        :key="`${curentPerson.ubo_related}_person_view_`"
                        :person="curentPerson"
                        ubo_person="true"
                        :source_of_funds="true"
                        :source_of_wealth="true"
                        :bank_reference_letter="true"
                        :google_search="true"
                        :world_check="true"
                        :preview="true"
                    ></PersonComp>
                    <PersonComp
                        v-else
                        :key="`${curentPerson.id}_person_view`"
                        :person="curentPerson"
                        :isOfficial="true"
                        :bank_reference_letter="true"
                        :world_check="true"
                        :google_search="true"
                        :preview="true"

                    ></PersonComp>
                </div> 
                <div class="flex  flex-1 gap-40 row" v-if="curentPerson && reviewAction.actionType == 'update'">
                    <div class="current-version flex-1 flex column" v-if="curentPerson">
                    <h3 class="header-small">Current Version</h3>

                    <div class="info-holder">
                        <p class="field-label">General Fields</p>
                        <InpComponent
                            class="country-select flex-1"
                            label="Origin"
                            type="options"
                            origin="kyc-section"

                            :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                            placeholder="Local"
                            theme="light"
                            light_label="true"
                            :value="curentPerson.location_type.value"
                            :class="{changes: localFields?.location_type?.new != undefined}"

                        ></InpComponent>
                        <InpComponent
                            origin="kyc-section"
                            class="flex-1"
                            label="Percentage of shares held"
                            type="number"
                            placeholder=""
                            :light_label="true"
                            theme="light"
                            :class="{changes: localFields?.percentage_held?.new != undefined}"

                            :value="curentPerson.percentage_held.value"
                            disabled="true"
                        ></InpComponent>
                        <InpComponent
                            origin="kyc-section"
                            class="flex-1"
                            label="Title"
                            :class="{changes: localFields?.title?.new != undefined}"
                            type="options"
                            :options="[
                                { value: 'Director', text: 'Director' },
                                { value: 'Secretary', text: 'Secretary' },
                                { value: 'MD', text: 'MD' },
                                { value: 'Registered Agent', text: 'Registered Agent' },
                            ]"
                            placeholder=""
                            :light_label="true"
                            theme="light"
                            :value="curentPerson.title.value"
                            disabled="true"
                        ></InpComponent>
                        <div class="flex-1">
                            <div class="passport-fields file-area">
                            <p class="field-label">Passport fields</p>
                            
                            <InpComponent
                                origin="kyc-section"
                                    label="First Name"
                                    type="text"
                                    placeholder="Don"
                                    :light_label="true"
                                    theme="light"
                                    :class="{changes: localFields?.firstName?.new != undefined}"

                                    :value="curentPerson.passport_file.firstName.value"
                                ></InpComponent>
                            
                                <InpComponent
                                    origin="kyc-section"
                                    label="Last Name"
                                    :class="{changes: localFields?.lastName?.new != undefined}"
                                    type="text"
                                    placeholder="Jon"
                                    :light_label="true"
                                    theme="light"
                                    :value="curentPerson.passport_file.lastName.value"
                                    ></InpComponent>

                                <InpComponent
                                    origin="kyc-section"
                                    label="Passport Number"
                                    type="number"
                                    :class="{changes: localFields?.passportNumber?.new != undefined}"
                                    :light_label="true"
                                    placeholder="number"
                                    theme="light"
                                    :value="curentPerson.passport_file.passportNumber.value"
                                ></InpComponent>
                                <InpComponent
                                    origin="kyc-section"
                                    class="country-select"
                                    label="Issue country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    placeholder="Curacao"
                                    :class="{changes: localFields?.passportCountry?.new != undefined}"
                                    theme="light"
                                    :light_label="true"
                                    :value="curentPerson.passport_file.passportCountry.value"
                                ></InpComponent>
                                <InpComponent
                                    origin="kyc-section"
                                    label="Issue Date"
                                    type="date"
                                    :class="{changes: localFields?.dateIssued?.new != undefined}"
                                    :light_label="true"
                                    placeholder="2024/01/01"
                                    theme="light"
                                    :value="curentPerson.passport_file.dateIssued.value"
                                ></InpComponent>

                                <InpComponent
                                    origin="kyc-section"
                                    label="Expiry Date"
                                    :class="{changes: localFields?.dateExpiry?.new != undefined}"
                                    type="date"
                                    :light_label="true"
                                    placeholder="2024/01/01"
                                    theme="light"
                                    :value="curentPerson.passport_file.dateExpiry.value"
                                ></InpComponent>

                                <InpComponent
                                    origin="kyc-section"
                                    label="Taxpayer Identification Number"
                                    type="number"
                                    :light_label="true"
                                    :class="{changes: localFields?.tinNumber?.new != undefined}"
                                    placeholder="number"
                                    theme="light"
                                    :value="curentPerson.passport_file.tinNumber.value"
                                ></InpComponent>
                            </div>
                        </div>
                        <div class="file-area flex-1">
                            <p class="field-label">Address fields</p>
                            <InpComponent
                                label="Address Line 1"
                                type="text"
                                placeholder="123 Main St"
                                light_label="true"
                                :class="{changes: localFields?.address_line_1?.new != undefined}"
                                disabled="true"
                                theme="light"
                                :value="curentPerson.address_file.address_line_1.value"
                            ></InpComponent>

                            <InpComponent
                                label="Address Line 2 (optional)"
                                type="text"
                                placeholder=""
                                :class="{changes: localFields?.address_line_2?.new != undefined}"
                                light_label="true"
                                disabled="true"
                                theme="light"
                                :value="curentPerson.address_file.address_line_2.value"
                            ></InpComponent>

                            <InpComponent
                                label="City / Town"
                                type="text"
                                light_label="true"
                                :class="{changes: localFields?.city?.new != undefined}"
                                placeholder="London"
                                disabled="true"
                                theme="light"
                                :value="curentPerson.address_file.city.value"
                            ></InpComponent>

                            <InpComponent
                                class="country-select"
                                label="Country"
                                type="options"
                                :options="Utils && Utils.getCountryList()"
                                placeholder="Canada"
                                :class="{changes: localFields?.address_country?.new != undefined}"
                                theme="light"
                                disabled="true"
                                light_label="true"
                                :value="curentPerson.address_file.address_country.value"
                            ></InpComponent>

                            <InpComponent
                                label="Zip"
                                type="number"
                                placeholder="00000"
                                disabled="true"
                                :class="{changes: localFields?.zip?.new != undefined}"
                                theme="light"
                                light_label="true"
                                :value="curentPerson.address_file.zip.value"
                            ></InpComponent>

                            <InpComponent
                                v-if="curentPerson.address_file.address_date_issues"
                                class="issue-date"
                                label="Issue Date"
                                :class="{changes: localFields?.address_date_issues?.new != undefined}"
                                type="date"
                                :light_label="true"
                                placeholder="2024/01/01"
                                theme="light"
                                disabled="true"
                                :value="curentPerson.address_file.address_date_issues.value"
                            ></InpComponent>
                        
                        </div>
                        <div class='file-area flex-1' v-if="
                            (
                                curentPerson.location_type.value != 'local'
                            )">
                            <p class="field-label">Second document fields</p>
                            <div class="passport-fields ">

                                <InpComponent
                                    origin="kyc-section"
                                    label="Document Type"
                                    type="options"
                                    :light_label="true"
                                    placeholder=""
                                    theme="light"
                                    :kyc_validate="true"
                                    :class="{changes: localFields?.secondPassportType?.new != undefined}"

                                    :options="[{value: 'id_card', text: 'ID Card'}, {value: 'driver_license', text: 'Driver License'}]"
                                    :value="curentPerson.secondPassport_file.secondPassportType.value"
                                ></InpComponent>

                                <InpComponent
                                    origin="kyc-section"
                                    :label="docLabel"
                                    type="number"
                                    :disabled="disabled_number"
                                    :light_label="true"
                                    placeholder="number"
                                    theme="light"
                                    :class="{changes: localFields?.secondPassportNumber?.new != undefined}"

                                    :value="curentPerson.secondPassport_file.secondPassportNumber.value"
                                ></InpComponent>
                                
                                <InpComponent
                                    origin="kyc-section"
                                    class="country-select"
                                    label="Issue country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    placeholder="Curacao"
                                    :class="{changes: localFields?.secondPassportCountry?.new != undefined}"
                                    theme="light"
                                    :value="curentPerson.secondPassport_file.secondPassportCountry.value"
                                ></InpComponent>

                                <InpComponent
                                    origin="kyc-section"
                                    label="Issue Date"
                                    type="date"
                                    :light_label="true"
                                    :class="{changes: localFields?.secondDateIssued?.new != undefined}"
                                    placeholder="2024/01/01"
                                    theme="light"
                                    :value="curentPerson.secondPassport_file.secondDateIssued.value"
                                ></InpComponent>

                                <InpComponent
                                    origin="kyc-section"
                                    label="Expiry Date"
                                    type="date"
                                    :light_label="true"
                                    placeholder="2024/01/01"
                                    :class="{changes: localFields?.secondDateExpiry?.new != undefined}"
                                    theme="light"
                                    :value="curentPerson.secondPassport_file.secondDateExpiry.value"
                                ></InpComponent>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="proposed-changes flex-1" v-if="curentPerson && localFields">
                    <h3 class="header-small">Adjusted Version</h3>
                    <div class="info-holder">
                        <div class="info-holder">
                            <p class="field-label">General Fields</p>
                            <InpComponent
                                class="country-select flex-1"
                                label="Origin"
                                type="options"
                                origin="kyc-section"
                                :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                                placeholder="Local"
                                theme="light"
                                light_label="true"
                                :value="localFields?.location_type?.new || curentPerson.location_type.value"
                                :class="{changes: localFields?.location_type?.new != undefined}"

                            ></InpComponent>
                            <InpComponent
                                class="flex-1"
                                label="Percentage of shares held"
                                :class="{changes: localFields?.percentage_held?.new != undefined}"
                                type="number"
                                placeholder=""
                                :light_label="true"
                                theme="light"
                                :value="localFields?.percentage_held?.new || curentPerson.percentage_held.value"
                                disabled="true"
                            ></InpComponent>
                            <InpComponent
                                origin="kyc-section"
                                class="flex-1"
                                :class="{changes: localFields?.title?.new != undefined}"

                                label="Title"
                                type="options"
                                :options="[
                                    { value: 'Director', text: 'Director' },
                                    { value: 'Secretary', text: 'Secretary' },
                                    { value: 'MD', text: 'MD' },
                                    { value: 'Registered Agent', text: 'Registered Agent' },
                                ]"
                                placeholder=""
                                :light_label="true"
                                theme="light"
                                :value="localFields?.title?.new || curentPerson.title.value"
                                disabled="true"
                            ></InpComponent>
                            <div class="flex-1">
                                <div class="passport-fields file-area">
                                <p class="field-label">Passport fields</p>
                                
                                <InpComponent
                                    origin="kyc-section"
                                        label="First Name"
                                        :class="{changes: localFields?.firstName?.new != undefined}"
                                        type="text"
                                        placeholder="Don"
                                        :light_label="true"
                                        theme="light"
                                        :value="localFields?.firstName?.new || curentPerson.passport_file.firstName.value"
                                    ></InpComponent>
                                
                                    <InpComponent
                                        origin="kyc-section"
                                        :class="{changes: localFields?.lastName?.new != undefined}"
                                        label="Last Name"
                                        type="text"
                                        placeholder="Jon"
                                        :light_label="true"
                                        theme="light"
                                        :value="localFields?.lastName?.new || curentPerson.passport_file.lastName.value"
                                    ></InpComponent>

                                    <InpComponent
                                        origin="kyc-section"
                                        label="Passport Number"
                                        type="number"
                                        :class="{changes: localFields?.passportNumber?.new != undefined}"
                                        :light_label="true"
                                        placeholder="number"
                                        theme="light"
                                        :value="localFields?.passportNumber?.new || curentPerson.passport_file.passportNumber.value"
                                    ></InpComponent>
                                    <InpComponent
                                        origin="kyc-section"
                                        class="country-select"
                                        :class="{changes: localFields?.passportCountry?.new != undefined}"
                                        label="Issue country"
                                        type="options"
                                        :options="Utils && Utils.getCountryList()"
                                        placeholder="Curacao"
                                        theme="light"
                                        :light_label="true"
                                        :value="localFields?.passportCountry?.new || curentPerson.passport_file.passportCountry.value"
                                    ></InpComponent>
                                    <InpComponent
                                        origin="kyc-section"
                                        label="Issue Date"
                                        :class="{changes: localFields?.dateIssued?.new != undefined}"
                                        type="date"
                                        :light_label="true"
                                        placeholder="2024/01/01"
                                        theme="light"
                                        :value="localFields?.dateIssued?.new || curentPerson.passport_file.dateIssued.value"
                                    ></InpComponent>

                                    <InpComponent
                                        origin="kyc-section"
                                        label="Expiry Date"
                                        type="date"
                                        :class="{changes: localFields?.dateExpiry?.new != undefined}"
                                        :light_label="true"
                                        placeholder="2024/01/01"
                                        theme="light"
                                        :value="localFields?.dateExpiry?.new || curentPerson.passport_file.dateExpiry.value"
                                    ></InpComponent>

                                    <InpComponent
                                        origin="kyc-section"
                                        label="Taxpayer Identification Number"
                                        type="number"
                                        :light_label="true"
                                        :class="{changes: localFields?.tinNumber?.new != undefined}"
                                        placeholder="number"
                                        theme="light"
                                        :value="localFields?.tinNumber?.new || curentPerson.passport_file.tinNumber.value"
                                    ></InpComponent>
                                </div>
                            </div>
                            <div class="file-area flex-1">
                                <p class="field-label">Address fields</p>
                                <InpComponent
                                    label="Address Line 1"
                                    type="text"
                                    placeholder="123 Main St"
                                    light_label="true"
                                    :class="{changes: localFields?.address_line_1?.new != undefined}"
                                    disabled="true"
                                    theme="light"
                                    :value="localFields?.address_line_1?.new || curentPerson.address_file.address_line_1.value"
                                ></InpComponent>

                                <InpComponent
                                    label="Address Line 2 (optional)"
                                    type="text"
                                    placeholder=""
                                    :class="{changes: localFields?.address_line_2?.new != undefined}"
                                    light_label="true"
                                    disabled="true"
                                    theme="light"
                                    :value="localFields?.address_line_2?.new || curentPerson.address_file.address_line_2.value"
                                ></InpComponent>

                                <InpComponent
                                    label="City / Town"
                                    type="text"
                                    light_label="true"
                                    :class="{changes: localFields?.city?.new != undefined}"
                                    placeholder="London"
                                    disabled="true"
                                    theme="light"
                                    :value="localFields?.city?.new || curentPerson.address_file.city.value"
                                ></InpComponent>

                                <InpComponent
                                    class="country-select"
                                    label="Country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    placeholder="Canada"
                                    :class="{changes: localFields?.address_country?.new != undefined}"
                                    theme="light"
                                    disabled="true"
                                    light_label="true"
                                    :value="localFields?.address_country?.new || curentPerson.address_file.address_country.value"
                                ></InpComponent>

                                <InpComponent
                                    label="Zip"
                                    type="number"
                                    placeholder="00000"
                                    disabled="true"
                                    :class="{changes: localFields?.zip?.new != undefined}"
                                    theme="light"
                                    light_label="true"
                                    :value="localFields?.zip?.new || curentPerson.address_file.zip.value"
                                ></InpComponent>

                                <InpComponent
                                    v-if="curentPerson.address_file.address_date_issues"
                                    class="issue-date"
                                    label="Issue Date"
                                    :class="{changes: localFields?.address_date_issues?.new != undefined}"
                                    type="date"
                                    :light_label="true"
                                    placeholder="2024/01/01"
                                    theme="light"
                                    disabled="true"
                                    :value="localFields?.address_date_issues?.new || curentPerson.address_file.address_date_issues.value"
                                ></InpComponent>
                            
                            </div>
                            <div class='file-area flex-1' v-if="(localFields?.location_type?.new != 'local' &&  curentPerson.location_type.value != 'local')">
                                <p class="field-label">Second document fields</p>
                                <div class="passport-fields ">

                                    <InpComponent
                                        origin="kyc-section"
                                        label="Document Type"
                                        type="options"
                                        :light_label="true"
                                        placeholder=""
                                        theme="light"

                                        :options="[{value: 'id_card', text: 'ID Card'}, {value: 'driver_license', text: 'Driver License'}]"
                                        :value="localFields?.secondPassportType?.new || curentPerson.secondPassport_file.secondPassportType.value"
                                    ></InpComponent>

                                    <InpComponent
                                        origin="kyc-section"
                                        :label="docLabel"
                                        type="number"
                                        :disabled="true"
                                        placeholder="number"
                                        theme="light"
                                        :value="localFields?.secondPassportNumber?.new || curentPerson.secondPassport_file.secondPassportNumber.value"
                                    ></InpComponent>
                                    
                                    <InpComponent
                                        origin="kyc-section"
                                        class="country-select"
                                        label="Issue country"
                                        type="options"
                                        :options="Utils && Utils.getCountryList()"
                                        placeholder="Curacao"
                                        disabled="true"
                                        theme="light"
                                        :light_label="true"
                                        :value="localFields?.secondPassportCountry?.new || curentPerson.secondPassport_file.secondPassportCountry.value"
                                    ></InpComponent>

                                    <InpComponent
                                        origin="kyc-section"
                                        label="Issue Date"
                                        type="date"
                                        :light_label="true"
                                        placeholder="2024/01/01"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.secondDateIssued?.new || curentPerson.secondPassport_file.secondDateIssued.value"
                                    ></InpComponent>

                                    <InpComponent
                                        origin="kyc-section"
                                        label="Expiry Date"
                                        type="date"
                                        :light_label="true"
                                        placeholder="2024/01/01"
                                        theme="light"
                                        :value="localFields?.secondDateIssued?.new || curentPerson.secondPassport_file.secondDateExpiry.value"
                                    ></InpComponent>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                
                
            </div>
            <div class="flex gap-40 w-cover padding-20" v-else-if="reviewAction.legalEntity">
                <div class="current-version flex-1 flex column" v-if="currentEntity && reviewAction.actionType == 'remove'">
                    <EntityView
                        :key="`entity_view_${currentEntity.id}`"
                        :entity="currentEntity"
                        :mbd="objectMap.mbds[currentEntity.mbd_related]"
                    ></EntityView>
                </div> 
                <div class="flex flex-1 gap-40 row" v-if="currentEntity && reviewAction.actionType == 'update'">
                    <div class="current-version flex-1 flex column" v-if="currentEntity">
                        <h3 class="header-small">Current Version</h3>

                        <div class="info-holder">
                            <p class="field-label">General Fields</p>
                                <InpComponent 
                                    class="country-select"
                                    label="Supervised"
                                    type="options"
                                    :options="[{value: false, text: 'No'}, {value: true, text: 'Yes'}]"
                                    placeholder="Local"
                                    theme="light"
                                    origin="kyc-section"
                                    disabled="true"
                                    light_label="true"
                                    :key_ref="'supervised'"
                                    :value="currentEntity.supervised.value"
                                    :class="{changes: localFields?.supervised?.new != undefined}"

                                ></InpComponent>
                                <InpComponent
                                    class="name-input"
                                    label="Name of the Entity"
                                    type="text"
                                    disabled="true"

                                    light_label="true"
                                    placeholder="Corporation Inc."
                                    theme="light"
                                    :class="{changes: localFields?.name?.new != undefined}"
                                    :value="currentEntity.name.value"
                                ></InpComponent>
                            <div class="file-area flex-1">
                                <p class="field-label">Address fields</p>
                                <InpComponent
                                    label="Address Line 1"
                                    type="text"
                                    placeholder="123 Main St"
                                    light_label="true"
                                    :class="{changes: localFields?.address_line_1?.new != undefined}"
                                    disabled="true"
                                    theme="light"
                                    :value="currentEntity.address.address_line_1.value"
                                ></InpComponent>

                                <InpComponent
                                    label="Address Line 2 (optional)"
                                    type="text"
                                    placeholder=""
                                    :class="{changes: localFields?.address_line_2?.new != undefined}"
                                    light_label="true"
                                    disabled="true"
                                    theme="light"
                                    :value="currentEntity.address.address_line_2.value"
                                ></InpComponent>

                                <InpComponent
                                    label="City / Town"
                                    type="text"
                                    light_label="true"
                                    :class="{changes: localFields?.city?.new != undefined}"
                                    placeholder="London"
                                    disabled="true"
                                    theme="light"
                                    :value="currentEntity.address.city.value"
                                ></InpComponent>

                                <InpComponent
                                    class="country-select"
                                    label="Country"
                                    type="options"
                                    :options="Utils && Utils.getCountryList()"
                                    placeholder="Canada"
                                    :class="{changes: localFields?.address_country?.new != undefined}"
                                    theme="light"
                                    disabled="true"
                                    light_label="true"
                                    :value="currentEntity.address.address_country.value"
                                ></InpComponent>

                                <InpComponent
                                    label="Zip"
                                    type="number"
                                    placeholder="00000"
                                    disabled="true"
                                    :class="{changes: localFields?.zip?.new != undefined}"
                                    theme="light"
                                    light_label="true"
                                    :value="currentEntity.address.zip.value"
                                ></InpComponent>       
                            </div>
                        </div>
                    </div>

                    <div class="proposed-changes flex-1 flex column" v-if="currentEntity">
                        <h3 class="header-small">Adjusted Version</h3>

                        <div class="info-holder">
                            <p class="field-label">General Fields</p>

                                <InpComponent 
                                    class="country-select"
                                    label="Supervised"
                                    type="options"
                                    :options="[{value: false, text: 'No'}, {value: true, text: 'Yes'}]"
                                    placeholder="Local"
                                    theme="light"
                                    disabled="true"
                                    light_label="true"
                                    :value="localFields?.supervised?.new || currentEntity.supervised.value"
                                    :class="{changes: localFields?.supervised?.new != undefined}"
                                ></InpComponent>
                                <InpComponent
                                    class="name-input"
                                    label="Name of the Entity"
                                    type="text"
                                    light_label="true"
                                    placeholder="Corporation Inc."
                                    theme="light"
                                    disabled="true"
                                    :class="{changes: localFields?.name?.new != undefined}"
                                    :value="localFields?.name?.new || currentEntity.name.value"
                                ></InpComponent>
                                <div class="file-area flex-1">
                                    <p class="field-label">Address fields</p>
                                    <InpComponent
                                        label="Address Line 1"
                                        type="text"
                                        placeholder="123 Main St"
                                        light_label="true"
                                        :class="{changes: localFields?.address_line_1?.new != undefined}"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.address_line_1?.new || currentEntity.address.address_line_1.value"
                                    ></InpComponent>

                                    <InpComponent
                                        label="Address Line 2 (optional)"
                                        type="text"
                                        placeholder=""
                                        :class="{changes: localFields?.address_line_2?.new != undefined}"
                                        light_label="true"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.address_line_2?.new || currentEntity.address.address_line_2.value"
                                    ></InpComponent>

                                    <InpComponent
                                        label="City / Town"
                                        type="text"
                                        light_label="true"
                                        :class="{changes: localFields?.city?.new != undefined}"
                                        placeholder="London"
                                        disabled="true"
                                        theme="light"
                                        :value="localFields?.city?.new || currentEntity.address.city.value"
                                    ></InpComponent>

                                    <InpComponent
                                        class="country-select"
                                        label="Country"
                                        type="options"
                                        :options="Utils && Utils.getCountryList()"
                                        placeholder="Canada"
                                        :class="{changes: localFields?.address_country?.new != undefined}"
                                        theme="light"
                                        disabled="true"
                                        light_label="true"
                                        :value="localFields?.address_country?.new || currentEntity.address.address_country.value"
                                    ></InpComponent>

                                    <InpComponent
                                        label="Zip"
                                        type="number"
                                        placeholder="00000"
                                        disabled="true"
                                        :class="{changes: localFields?.zip?.new != undefined}"
                                        theme="light"
                                        light_label="true"
                                        :value="localFields?.zip?.new || currentEntity.address.zip.value"
                                    ></InpComponent>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="flex gap-40 w-cover padding-20" v-else-if="reviewAction.ubo_related">
                <div class="current-version flex-1 flex column" v-if="currentUBO">
                    <p class=field-label>Delete action requested on the following element</p>
                    <UBO
                        :key="`index_ubo-${currentUBO.id}`"
                        :ubo="currentUBO.struct"
                        :location_type="currentUBO.location_type"
                        :id="currentUBO.id"
                        :preview="true"
                    ></UBO>
                </div>
            </div>
            <div class="flex gap-40 w-cover padding-20" v-else-if="reviewAction.mbd_related">

                <div class="current-version flex-1 flex column" v-if="currentMD && reviewAction.actionType == 'remove'">
                    <ManagingDirrector 
                        :key="`index_mbd-${currentMD.id}`"
                        :mbd="currentMD"
                        class="managing-director"
                        :preview="true"
                    />
                </div> 

                <div class="flex flex-1 gap-40 row" v-if="currentMD && reviewAction.actionType == 'update'">

                    <div class="current-version flex-1 flex column" v-if="currentMD">
                        <h3 class="header-small">Current Version</h3>

                        <div class="info-holder">
                            <p class="field-label">General Fields</p>
                            <InpComponent
                                class="country-select"
                                label="Origin"
                                type="options"
                                :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                                placeholder="Local"
                                theme="light"
                                :disabled="true"
                                light_label="true"
                                :class="{changes: localFields?.location_type?.new != undefined}"
                                :value="currentMD['location_type'].value"

                            ></InpComponent>
                            <InpComponent 
                                v-if="currentMD['location_type'].value === 'abroad'"
                                class="country-select"
                                label="Director Type"
                                type="options"
                                :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Legal entity'}]"
                                placeholder="Local"
                                theme="light"
                                :disabled="true"
                                light_label="true"
                                :class="{changes: localFields?.entity_type?.new != undefined}"
                                :value="currentMD['entity_type'].value"
                            ></InpComponent>
                        </div>
                    </div>

                    <div class="proposed-version flex-1 flex column" v-if="currentMD">
                        <h3 class="header-small">Adjusted Version</h3>

                        <div class="info-holder">
                            <p class="field-label">General Fields</p>
                            <InpComponent
                                class="country-select"
                                label="Origin"
                                type="options"
                                :options="[{value: 'local', text: 'Resident'}, {value: 'abroad', text: 'Non-resident'}]"
                                placeholder="Local"
                                theme="light"
                                :disabled="true"
                                light_label="true"
                                :class="{changes: localFields?.location_type?.new != undefined}"
                                :value="localFields?.location_type?.new || currentMD['location_type'].value"

                            ></InpComponent>
                            <InpComponent 
                                v-if="currentMD['location_type'].value === 'abroad'"
                                class="country-select"
                                label="Director Type"
                                type="options"
                                :options="[{value: 'person', text: 'Individual'}, {value: 'entity', text: 'Legal entity'}]"
                                placeholder="Local"
                                theme="light"
                                :disabled="true"
                                light_label="true"
                                :class="{changes: localFields?.entity_type?.new != undefined}"
                                :value="localFields?.entity_type?.new || currentMD['entity_type'].value"
                            ></InpComponent>
                        </div>
                    </div>

                </div>
            </div> 
            <div class="flex gap-40 w-cover padding-20" v-else-if="reviewAction.listingService">

                <div class="current-changes flex-1 flex column" v-if="currentListing">
                    <h3 class="header-small">Current Version</h3>

                    <div class="info-holder">
                        <textarea
                            class="textarea"
                            :value="currentListing?.value"
                            disabled="true"
                        ></textarea>
                    </div>
                </div>
                <div class="proposed-changes flex-1 flex column" v-if="currentListing">
                    <h3 class="header-small">Adjusted Version</h3>
                    <div class="info-holder">
                        <textarea
                            class="textarea"
                            :value="localFields?.text_val?.new"
                            disabled="true"
                        ></textarea>
                    </div>
                </div>
            </div>
            <div v-else>
                {{reviewAction}}

                <br>
                <br>
                <br>
                {{ objectMap.files }}
            </div>
        </div>
        <div class="add-new-ticket-info flex gap-5">
            <button class="transparent cancel-btn" @click="denyChanges()">Deny</button>
            <button @click="approveChanges()">Approve</button>
        </div>

        <PopupCover v-if="deleteObject" >
            <ConfirmationPopup 
                @close="popupConfirm"
                :title="'Delete Subsidiary'"
                :message="'Are you sure you want to delete this subsidiary?'"
            />
        </PopupCover>
    </div>
</template>

<script setup>
    import { ref, defineEmits, defineProps, computed, onMounted } from 'vue'
    import { useStore } from 'vuex'
    import { useRouter } from 'vue-router'
    import Utils from '../utils/index.js'
    import UBO from '@/views/UBO.vue'
    import PersonComp from '@/components/PersonComp.vue'
    import EntityView from '@/components/EntityView.vue'
    import ManagingDirrector from '@/components/ManagingDirrector.vue'
    import InpComponent from '@/components/InpComponent.vue';
    import AddressComp from '@/components/AddressComp.vue';
    import CommentSection from '@/views/CommentSection.vue';
    import PopupCover from '@/components/PopupCover.vue';
    import ConfirmationPopup from '@/components/ConfirmationPopup.vue';
    import API from '@/api/index';
    import dayjs from 'dayjs';
    import UploaderTable from '@/components/UploaderTable.vue';
    function renderTime(time) {
        if (!time) return '';
        return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
    }
    const store = useStore();
    const emit = defineEmits(['close']);
    const objectMap = computed(() => store.state.objectMap);
    const dataStructure = computed(() => store.state.dataStructureStore);
    const props = defineProps([
        'reviewAction', 
    ])
    const localReviewAction = ref(props.reviewAction);
    
    const localFields = ref(JSON.parse(props.reviewAction.fields));
    const currentFile = ref(null);
    const curentPerson = ref(null);
    const currentEntity = ref(null);
    const currentMD = ref(null);
    const currentListing = ref(null);
    const currentUBO = ref(null);



    const getDocumentType = computed(() => {
        if (currentFile.value) {
            if (currentFile.value.documentType == 'subsidiaries') {
                return `${currentFile.value.name} annual accounts - last book year`
            }
            if (currentFile.value.documentType == 'scfba') {
                return `Signature card for bank account`
            }
            if (currentFile.value.documentType == 'cosbaof') {
                return 'Copy of signed bank account opening forms'
            }

            return currentFile.value.title;
        }
    })
      

    onMounted(() => {
        if (props.reviewAction.kyc_file) {
            currentFile.value = objectMap.value.files[props.reviewAction.kyc_file];
        }
        if (props.reviewAction.person) {
            curentPerson.value = objectMap.value.people[props.reviewAction.person];
        }
        if (props.reviewAction.legalEntity) {
            currentEntity.value = objectMap.value.entities[props.reviewAction.legalEntity];
        }
        if (props.reviewAction.ubo_related) {
            currentUBO.value = objectMap.value.ubos[props.reviewAction.ubo_related];
        }
        if (props.reviewAction.mbd_related) {
            currentMD.value = objectMap.value.mbds[props.reviewAction.mbd_related];
        }
        if (props.reviewAction.listingService) {
            currentListing.value = dataStructure.value['textarea'];
        }
       
    })

    function closePopup() {
        emit('close')
    }
    async function approveChanges(){
        console.log('props.reviewAction', props.reviewAction._id)
        const response = await API.approveChanges({id: props.reviewAction._id});
        console.log('response', response)
        console.log('response', response.status)
        console.log('response status', response.status == 'success')
        if (response.status == 'success') {
            console.log('file', response.file)
            // update File state here
            store.dispatch('addNotification', {
                type: 'success',
                text: 'Changes approved successfully'
            });

            store.state.confirmationNeeded = store.state.confirmationNeeded.filter(el => el._id != props.reviewAction._id)
            // update file ubo mbd entity or person after successfull approval
            closePopup();
        }
    }
    async function denyChanges() {
        console.log('props.reviewAction', props.reviewAction._id)
        const response = await API.denyChanges({id: props.reviewAction._id});
        console.log('response', response)
        console.log('response', response.status)
        console.log('response status', response.status == 'success')
        if (response.status == 'success') {
            console.log('file', response.file)
            // update File state here
            store.dispatch('addNotification', {
                type: 'success',
                text: 'Changes denied successfully'
            });

            store.state.confirmationNeeded = store.state.confirmationNeeded.filter(el => el._id != props.reviewAction._id)
            // update file ubo mbd entity or person after successfull approval
            closePopup();
        }
    }
    

</script>


<style scoped lang="scss">

    .input-row {
        > * {
            flex: 1;
        }
    }
    .info-section-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: self-start;
    }
    .info-section {
        text-align: left;
        line-height: 18px;
    }
    .name {
        font-size: 14px;
        color: rgb(116 119 127);
    }
    .value {
        font-size: 16px;
        color: black;
        font-weight: bold;
    }
    .field-label {
        font-size: 14px;
        color: black;
        font-weight: 600;
        margin: 0;
        margin-bottom: 10px;
        text-align: left;
    }
    .button-delete {
        align-items: flex-end;
    }
    .header-small {
        text-align: left;
        color: black;
        font-size: 16px;
        margin: 0;
        margin-bottom: 10px;
    }
    .header-of-comments {
        text-align: left;
        font-size: 16px;
        color: black;
        font-weight: 600;
        padding-left: 5px;
    }
    .generate-password {
        border-radius: 5px;
        padding: 5px 10px;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        height: 26px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 2;
        &:hover {
            cursor: pointer;
            background-color: var(--button-hover-color-blue);
        }

    }

    .main-container {
        max-height: 80vh;
        overflow: auto;
    }
    .container {
        border-radius: .5rem;
        display: flex;
        flex-direction: column;
        width: 1100px;
        max-width: 95vw;
        border: 1px solid var(--border-color);
        max-height: 100vh;
        overflow-y: auto;
        padding: 20px;
        box-sizing: border-box;
        max-height: 95vh;
        background-color: white;
    } 
    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        font-size: 1.5rem;
        font-weight: 700;
        line-height: 2rem;
        color: black;
    }
    .cancel-btn {
        margin-left: auto;
        margin-right: 10px;
    }
    .add-new-ticket-info {
        margin-top: auto;
        margin-left: auto;
        button {
            width: fit-content;
            padding: 10px 30px;
        }
    }
    .info-line {
        color: black;
        text-align: left;
        font-weight: 600;
        margin: 0;
    }
    .line-label {
        text-align: left;
        font-weight: 600;
        margin: 0;
        margin-top: 5px;
        font-size: 12px;
        color: rgb(151, 151, 151);
    }
    .cross {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img {
            width: 20px;
            height: 20px;
        }
    }
    .ticket-details {
        font-size: 1.2rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: black;
        padding-bottom: 20px;
        text-align: left;
    }
    .proposed-changes {
        .changes {
            background: #DEF7EC;
            &:deep(label) {
                background: #DEF7EC;
            }
        }
    }
    .current-version {
        .changes {
            background: #FDE8E8;
            &:deep(label) {
                background: #FDE8E8;
            }
        }
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        border-radius: 10px;
        min-height: 150px;
        max-height: 300px;
        height: fit-content;
        width: 100%
    }
    .markdown-content {
        height: 200px;
        max-height: 200px;
        :nth-child(1) {
            text-align: left;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        &:deep(.ql-container){
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        &.disabled {
            background-color: #f5f5f5;
            opacity: 0.6;
        }
    }
    .w-cover {
        width: 99%;
    }
    .header-of-section {
        color: black;
        text-align: left;
        font-weight: 600;
        font-size: 16px;
    }
    .info-section {
        width: 95%;
    }
    .comment {
        padding: 10px;
        background-color: #f5f5f5;
        border-radius: 5px;
        width: 100%;
        min-height: 60px;
        font-weight: 300;
        font-size: 14px;
        color: black;
        opacity: 0.6;
        border: 1px solid var(--border-color);
        &:deep(*) {
            margin: 0;
        }
    }
    .uploader-table {
        flex: 2;
    }
    .comment-info {
        flex: 3;
    }
    .info-holder {
        margin-bottom: 20px;
    }
</style>