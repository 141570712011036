<template>
    <div class="passport-fields">
        <InpComponent
            label="Address Line 1"
            type="text"
            placeholder="123 Main St"
            light_label="true"
            :origin="originLocal"
            :kyc_validate="true"
            theme="light"
            :key_ref="'address_line_1'"
            :value="localAddress.address_line_1.value"
            :error="localAddress.address_line_1.error"
            @inputChange="inputChangeFunc"
        ></InpComponent>

        <InpComponent
            label="Address Line 2 (optional)"
            type="text"
            placeholder=""
            light_label="true"
            :origin="originLocal"
            :kyc_validate="true"
            theme="light"
            :key_ref="'address_line_2'"
            :value="localAddress.address_line_2.value"
            :error="localAddress.address_line_2.error"
            @inputChange="inputChangeFunc"
        ></InpComponent>

        <InpComponent
            label="City / Town"
            type="text"
            light_label="true"
            placeholder="London"
            :origin="originLocal"
            :kyc_validate="true"
            theme="light"
            :key_ref="'city'"
            :value="localAddress.city.value"
            :error="localAddress.city.error"
            @inputChange="inputChangeFunc"
        ></InpComponent>

        <InpComponent
            class="country-select"
            label="Country"
            type="options"
            :options="Utils && Utils.getCountryList()"
            placeholder="Canada"
            :origin="originLocal"
            :kyc_validate="true"
            theme="light"
            light_label="true"
            :key_ref="'address_country'"
            :value="localAddress.address_country.value"
            :error="localAddress.address_country.error"
            @inputChange="inputChangeFunc"
        ></InpComponent>

        <InpComponent
            label="Zip"
            type="number"
            placeholder="00000"
            :origin="originLocal"
            :kyc_validate="true"
            theme="light"
            light_label="true"
            :key_ref="'zip'"
            :value="localAddress.zip.value"
            :error="localAddress.zip.error"
            @inputChange="inputChangeFunc"
        ></InpComponent>

        <InpComponent
            v-if="localAddress.address_date_issues"
            class="issue-date"
            
            label="Issue Date"
            type="date"
            :light_label="true"
            placeholder="2024/01/01"
            :origin="originLocal"
            :kyc_validate="true"
            theme="light"
            :key_ref="'address_date_issues'"
            :value="localAddress.address_date_issues.value"
            :error="localAddress.address_date_issues.error"
            @inputChange="inputChangeFunc"
        ></InpComponent>

    </div>
</template>

<script setup>
    import InpComponent from '@/components/InpComponent.vue'
    import Utils from '@/utils/index.js'
    import { ref, defineEmits } from 'vue'
    const props = defineProps(['address', 'origin'])

    const originLocal = ref(props.origin || 'default') 

    const emit = defineEmits(['addressChange'])
    const localAddress = ref(props.address)

    function inputChangeFunc(event) {
        const event_type = event[0];
        const event_value = event[1];
        
        emit('addressChange', {
            property: event_type,
            value: event_value
        })
    }

</script>

<style scoped lang="scss">
.passport-fields {
    > * {
        &:last-child {
            margin-bottom: 0;
        }
    } 
  
}

</style>