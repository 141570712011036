<template>
    <div class="kyc-view">
        <div class='snapshot-item' v-if="selectedSnapshot">
                <div>Viewing {{ selectedSnapshot.label }} snapshot of {{ dayjs(selectedSnapshot.snapshotDate, 'YYYY-MM-DD').format('DD/MM/YYYY') }}</div>
                <img @click="resetToCurrentView()" class='close-img' src="@/assets/cross.svg" alt="close" />
        </div>
        <div v-if="loaded=false">LOADING...</div>
        <div class="header-section">

            <div class="kyc-header">
                <div class="kyc-title">KYC Management</div>
                <div class="tab-views">
                    <div v-if="user && user.type == 'COMPLIANCE_OFFICER'" class='tab' :class="`${stateView == 'dashboard' ? 'active' : ''}`" @click="changeView('dashboard')"><div class="line"></div>Dashboard</div>
                    <div class='tab' :class="`${stateView == 'corporate' ? 'active' : ''}`" @click="changeView('corporate')"><div class="line"></div>Corporate</div>
                    <div class='tab' :class="`${stateView == 'ubo' ? 'active' : ''}`" @click="changeView('ubo')"><div class="line"></div>UBO</div>
                    <div class='tab' v-if="user.type != 'CLIENT'" :class="`${stateView == 'mbod' ? 'active' : ''}`" @click="changeView('mbod')"><div class="line"></div>Managing board of directors</div>
                    <div 
                        v-if="user && ['COMPLIENCE_OFFICER','EXTERNAL_AUDITOR','INTERNAL_AUDITOR'].includes(user.type)"
                        class='tab' :class="`${stateView == 'comment_section' ? 'active' : ''}`" @click="changeView('comment_section')"><div class="line"></div>Comment Section</div>
                    
                    <div class="tab confirmation-needed" 
                        v-if="user.type == 'DATA_PROCESSOR'"
                        :class="`${stateView == 'action-required' ? 'active' : ''}`" 
                        @click="changeView('action-required')"
                        
                        >Action Required</div>
                    <div class='tab notifications' v-if="user.type != 'CLIENT'" :class="`${stateView == 'notifications' ? 'active' : ''}`" @click="changeView('notifications')"><div class="line"></div>
                        Notifications
                        <div class='notification-counter' v-if="notificationsCount"> {{ notificationsCount }}</div>
                    </div>
                    <div class="tab" v-if="user && user.is_super_user" :class="`${stateView == 'timeline' ? 'active' : ''}`" @click="changeView('timeline')">
                        <div class="line"></div>
                        TimeLines
                    </div>
                </div>
            </div>
        </div>
        <div class="kyc-fields-container" v-if="dataStructure">
            <div class="ubos" v-if="!['notifications','action-required'].includes(stateView) ">
                <button class="add-new fit-content" @click="Save()"> Save </button>
            </div>
            <TimeLine v-if="stateView === 'timeline'"
                :companyData="companyData"
                :dataStructure="dataStructure">

            </TimeLine>

            <kycTabSetting 
                v-if="stateView === 'dashboard' && user.type == 'COMPLIANCE_OFFICER' "
                :companyData="companyData"
                :dataStructure="dataStructure"  
                @changeCompanyData="companyUpdate"
            />
            <generalKYCView 
                v-if="stateView === 'corporate'"
                :dataStructure="dataStructure" 
                @handleFileChange="handleFileChange"
                @textareaUpdates="textareaUpdates"
                @handleDateChange="handleDateChange"
                @commentChange="commentChange"
                @addnewDocument="addnewDocument"
                @changeInfo="changeInfo"
            />
            <div class="ubos" v-if="stateView === 'ubo'">

                <div class="flex row align-center mb-20">
                    <h3 class="doc-header">Ultimate Beneficial Owners that own more than 10% of the company </h3>
                    <div style="margin-left: auto" v-if="user.type == 'DATA_CONTROLLER'">
                        <button class="add-new no-margin fit-content" @click="createUbo()">Add UBO</button>
                    </div>
                </div>
                <UBO v-for="(ubo, index) in dataStructure['ubos']"
                    :key="`index_ubo-${ubo.id}`"
                    :ubo="ubo.struct"
                    :location_type="ubo.location_type"
                    :id="ubo.id"
                    @handleStructureUpdates="handleStructureUpdates"
                    @update_ubo="update_ubo"
                    @deleteUbo="deleteUbo"
                    >
                </UBO>
            </div>
            <div class="ubos" v-if="stateView === 'mbod'">
                <div class="flex row align-center mb-20">
                    <h3 class="doc-header">Managing board of directors</h3>
                    <div style="margin-left: auto" v-if="user.type == 'DATA_CONTROLLER'">
                        <button class="add-new no-margin fit-content" @click="createMdob()">Add Managing Director</button>
                    </div>
                </div>
                <ManagingDirrector 
                    v-if="user.type != 'CLIENT'"
                    v-for="(mbd, index) in dataStructure['mbds']"
                    :key="`index_mbd-${mbd.id}`"
                    :mbd="mbd"
                    
                    @handleFileChange="handleFileChange"
                    @inputChangeFunc="inputChangeFunc"
                    @update_mbd="update_mbd"
                    @deleteMBD="deleteMBD"
                    ref="ManagingDirrectorRefs"
                    class="managing-director"
                />
            </div>
            <div v-if="stateView === 'notifications' && user && user.type == 'COMPLIANCE_OFFICER'">
                <NotificationsView @updatingURL="updatingURL"/>
            </div>
            <div v-if="stateView === 'action-required' && user && user.type == 'DATA_PROCESSOR'">
                <ActionRequired />
            </div>
            <div v-if="stateView === 'comment_section'">
                <div class="cover-comments">

                    <CommentSection 
                        v-if="commentSection"
                        :commentSection="commentSection"
                    />
                </div>

            </div>  
            <div v-else>
                
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'

import ManagingDirrector from '@/components/ManagingDirrector.vue'

import API from '../api/index.js'
import UBO from '@/views/UBO.vue'
import generalKYCView from './generalKYCView.vue'
import CommentSection from './CommentSection.vue'
import NotificationsView from './NotificationsView.vue'
import TimeLine from './TimeLine.vue'

import ActionRequired from './ActionRequired.vue'
import kycTabSetting from './kycTabSetting.vue'
import {

    getUboStructure, getMbodStructure,
    addPersonToUBO,
    objectId
} from '../utils/kyc_utils.js'

import dayjs from 'dayjs'
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router'
import Utils from '@/utils/index.js'
import store from '../store';
import { mapState } from 'vuex';
import { compileString } from 'sass'


const router = useRouter();
const route = useRoute();


const query = ref(route.query)

const pageView =  ref(query.value.view)
const focusElement = ref(query.value.focus_element)
const snapshotId = ref(query.value.snapshot)
const loaded = computed(() => store.state.kycDataLoaded)
const selectedSnapshot = computed(() => store.state.selectedSnapshot)
const selectedFiles = ref({})
const dataStructure = computed(() => store.state.dataStructureStore);
const objectMap = computed(() => store.state.objectMap);
const companyData = computed(() => store.state.companyData);
const companyDataInitial = computed(() => store.state.companyDataInitial);
const itemsSaved = ref(0);
 
const commentSection = computed(() => store.state.commentSection);
const commentSectionInitial = computed(() => store.state.commentSectionInitial);


const popupShown = ref(false);
const popupType = ref('');
const stateView = ref(pageView.value || 'corporate')
const ManagingDirrectorRefs = ref([])
const user = computed(() => store.state.user);
const notificationsCount = computed(() => store.state.notificationsCount);
const validationOn = computed(() => store.state.validationOn);

const initialCommentString = computed(() => {
    if (!commentSectionInitial.value) {
        return null;
    }
    
    return JSON.stringify(commentSectionInitial.value)

})

const commentSectionString = computed(() => {
    if (!commentSection.value) {
        return null;
    }
    return JSON.stringify(commentSection.value)
})


async function loadData(snapshot = null) {
    let id = null;
    if (selectedSnapshot.value && selectedSnapshot.value._id) {
        id = selectedSnapshot.value._id
    }
    const response = await API.getKYCFilesList(id);
}

async function resetToCurrentView(){
    store.dispatch('setValue', { key: 'selectedSnapshot', value: null })
    await loadData();
}



onMounted(async() => {
    if (!user.value) {
        router.push('/login')
        return;
    }
    await loadData();
});


function changeView(event){
    stateView.value = event;
    router.push({ query: { view: event } });
    // stateView.value = event;
}


function updatingURL(event) {
    changeView(event.base);
    setTimeout(() => {
        const element = document.getElementById(event.id)
        if (!element) {
            alert('Element not found' +  element)
            return;
        }
        const y = element.getBoundingClientRect().top + window.scrollY - 100;

        // window.scrollTo({top: y, behavior: 'smooth'});
        element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }, 600)

}
function textareaUpdates(value){
    dataStructure.value['textarea'].new_value = value;
}


function createUbo(){
    dataStructure.value['ubos'].unshift(
        getUboStructure()
    )
}
function createMdob() {
    dataStructure.value['mbds'].unshift(
        getMbodStructure({is_new: true})
    )
}

function addnewDocument(structure) {
    dataStructure.value[structure].push({
        id: objectId(),
        new_file: '',
        file: null,
    })
}
function clearPersonFields(person) {
    for (let personProp in person) {
        if (personProp === 'id') continue;
        if (['percentage_held', 'location_type', 'title'].includes(personProp)) {
            person[personProp].new_value = ''
        }
        for(let personField in person[personProp]) {
            if (personField == 'title' || personField == 'file'){
                continue;
            }
            if (person[personProp][personField] && person[personProp][personField].new_value) {
                person[personProp][personField].new_value = ''
            }
        }
    }
}

function clearEntityFields(legalEntity) {
    for (let prop in legalEntity) {
        if (['id', 'mbd_ubo', ''].includes(prop)) {
            continue;
        }
        if (prop == 'address') {
            for (let addressProp in legalEntity[prop]) {
                if (legalEntity[prop][addressProp].new_value) {
                    legalEntity[prop][addressProp].new_value = ''
                }
            }
            continue
        }
        if (['name','supervised','location_type', 'entity_type'].includes(prop)) {
            if (legalEntity[prop].new_value || legalEntity[prop].new_value === false) {
                legalEntity[prop].new_value = ''
            }
            continue
        }
    }
}




async function uploadPerson(person, ubo, mbd){
    let top_level_properties = []

    let uploadedError = false;
    if (person.title.new_value) {
        top_level_properties.push({ title: person.title.new_value })
        person.title.value = person.title.new_value
    }
    if (person.percentage_held.new_value) {
        top_level_properties.push({ percentage_held: person.percentage_held.new_value })
        person.percentage_held.value = person.percentage_held.new_value
    }
    let location_type = null;
    if (person.location_type.new_value) {
        top_level_properties.push({ location_type: person.location_type.new_value })
        person.location_type.value = person.location_type.new_value
        location_type = person.location_type.new_value
    }
    for (let personProp in person) {
        if (['id','percentage_held', 'title', 'location_type'].includes(personProp)) continue;
        const objectToSend = {};
        let file = null;
        let localFields = []
        // CAN be redone to send all the fields at once
        for(let personField in person[personProp]) {
            if ([
                'new_comment_data_handler', 
                'new_comment_compliance_officer', 
                'new_comment_supervisor',
                'new_dateIssued'].includes(personField)
                && person[personProp][personField]
            ) {
                const key = personField.replace('new_', '')
                if (person[personProp][personField]) {
                    objectToSend[key] = person[personProp][key]
                }
                continue;
            }
            if (['title', 'file'].includes(personField)) {
                continue;
            }
            if (person[personProp][personField].new_value) {
                localFields.push({ 
                    [personField]: person[personProp][personField].new_value
                })
                continue;
            }
            if (personField == 'new_file' && person[personProp][personField]) {
                file = person[personProp]['file']
            }
        }
        if (!(file || Object.keys(objectToSend).length || localFields.length || top_level_properties.length) ) {
           continue;
        }
        console.log('person', person)

        console.log('AAAAAAAAAAAAAA',{localFields, top_level_properties})
        const combinedList = [...localFields, ...top_level_properties]
        const response = await API.uploadKYCFile(
            file,
            { 
                person_id: person.id,
                ubo: ubo ? JSON.stringify(ubo) : '',
                mbd: mbd ? JSON.stringify(mbd) : '',
                fileType: personProp, 
                location_type: location_type || ubo?.location_type || mbd?.location_type.value,
                type: 'ubo_person',
                ...objectToSend,
                text_field_values: combinedList ? JSON.stringify(combinedList) : undefined,
            }
        )
        if (response.status == 'success') {
            itemsSaved.value++;
            if (response.kyc_file) {
                person[personProp]['file'] = response.kyc_file;
                person[personProp]['new_file'] = false
            }
            for (let key in objectToSend) {
                person[personProp][`new_${key}`] = false;
            }
            top_level_properties = [];
        } else {
            uploadedError = true;
        }
    }
    if (!uploadedError) {
        clearPersonFields(person)
    }
}


async function uploadEntity(entity, mbd) {
    const legalEntity = entity;
    let entity_fields = []


    for (let addressProp in legalEntity['address']) {
        if (legalEntity['address'][addressProp].new_value) {
            entity_fields.push({ [addressProp]: legalEntity['address'][addressProp].new_value })
        }
    }
    if (legalEntity['name'].new_value) {
        entity_fields.push({ name: legalEntity['name'].new_value })
    }
    if (legalEntity['supervised'].new_value) {
        entity_fields.push({ supervised: legalEntity['supervised'].new_value })
    }
    // if (legalEntity['location_type'].new_value) {
    //     entity_fields.push({ location_type: legalEntity['location_type'].new_value })
    // }
    // if (legalEntity['entity_type'].new_value) {
    //     entity_fields.push({ entity_type: legalEntity['entity_type'].new_value })
    // }
    const uploadErrors = null;
    for (let prop in legalEntity) {
        
        if ([ 'id', 'name', 'supervised', 'location_type', 'entity_type', 'address'].includes(prop)) {
            continue
        }
        if (prop == 'mbd_ubo') {
            for (let ubo of legalEntity[prop]) {
                await uploadUbo(ubo, mbd)
            }
            continue
        }
     
        let file = null;
        let ObjectsToAttach = {};
        if (
            legalEntity[prop]['new_comment_data_handler'] ||
            legalEntity[prop]['new_comment_compliance_officer'] ||
            legalEntity[prop]['new_comment_supervisor']
        ) {
          
            if (legalEntity[prop]['new_comment_data_handler']) {
                ObjectsToAttach['comment_data_handler'] = legalEntity[prop]['comment_data_handler']
            }
            if (legalEntity[prop]['new_comment_compliance_officer']) {
                ObjectsToAttach['comment_compliance_officer'] = legalEntity[prop]['comment_compliance_officer']
            }
            if (legalEntity[prop]['new_comment_supervisor']) {
                ObjectsToAttach['comment_supervisor'] = legalEntity[prop]['comment_supervisor']
            }
        
        }
        if (legalEntity[prop].new_file) {
            file = legalEntity[prop].file
        }  
        if (!(file || Object.keys(ObjectsToAttach).length || entity_fields.length)) {
            continue;
        }
        const response = await API.uploadKYCFile(
            legalEntity[prop].file, 
            { 
                mbd: JSON.stringify(mbd),
                fileType: prop, 
                legalEntity_id: legalEntity.id,
                ...ObjectsToAttach,
                text_field_values: entity_fields.length ? JSON.stringify(entity_fields) : undefined,
            }
        );
        if (response.status == 'success') {
            entity_fields = [];
            legalEntity[prop].new_file = false
            itemsSaved.value++;
            legalEntity[prop]['new_comment_data_handler'] = false;
            legalEntity[prop]['new_comment_compliance_officer'] = false;
            legalEntity[prop]['new_comment_supervisor'] = false;
        } else {
            uploadErrors = true;
        }
    }
    if (!uploadErrors) {
        clearEntityFields(legalEntity)
    }
}

function deleteMBD(event) {
    let index = dataStructure.value.mbds.findIndex(el => el.id = event.id)
    if (index != -1) {
        store.dispatch('addNotification', {text: 'Something went wrong', type: 'error'})
        return;
    }
    dataStructure.value.mbds.splice(index, 1);
}
function deleteUbo(event) {
    let index = dataStructure.value.ubos.findIndex(el => el.id = event.id)
    if (index != -1) {
        store.dispatch('addNotification', {text: 'Something went wrong', type: 'error'})
        return;
    }
    dataStructure.value.ubos.splice(index, 1);
}
async function uploadMBD(mbds) {
    let mdbAPIData = {
        id: mbds.id,
        location_type: mbds.location_type.new_value || mbds.location_type.value,
        entity_type: mbds.entity_type.new_value || mbds.entity_type.value,
        // supervised: mbds.supervised.new_value || mbds.supervised.value,
    }
    if (mbds.struct.person){
        for (let mbdsPerson of mbds.struct.person) {
            await uploadPerson(mbdsPerson, null, mdbAPIData)
        }
    }
    let mbd_fields = []
    
    if (mbds.location_type.new_value) {
        mbd_fields.push({ location_type: mbds.location_type.new_value })
        mbds.location_type.value = mbds.location_type.new_value
    }
    if (mbds.entity_type.new_value) {
        mbd_fields.push({ entity_type: mbds.entity_type.new_value })
        mbds.entity_type.value = mbds.entity_type.new_value
    }
    if (mbd_fields.length){
        let response = await API.uploadKYCFile(
            null, 
            { 
                mbd: JSON.stringify(mdbAPIData),
                top_level_properties: JSON.stringify(mbd_fields), 
            }
        );
        if (response.status == 'success') {
            mbds.location_type.new_value = ''
            mbds.entity_type.new_value = ''
            mbds.supervised.new_value = ''
            itemsSaved.value++;
        }
    }

    if (mbds.struct.legalEntity) {
        uploadEntity(mbds.struct.legalEntity, mdbAPIData)
    }
}

async function uploadUbo(ubo, mbd){
    let uboApiData = ubo ? {
        id: ubo?.id,
        location_type: ubo?.location_type,
    } : null
    
    let mdbAPIData = mbd ? {
        id: mbd?.id,
        location_type: mbd?.location_type.new_value || mbd?.location_type.value,
        entity_type: mbd?.entity_type.new_value || mbd?.entity_type.value,
        // supervised: mbd?.supervised.new_value || mbd?.supervised.value,
    } : null

    for (let prop in ubo.struct) {
        if (prop == 'person') {
            const person = ubo.struct[prop];
            await uploadPerson(person, uboApiData, mdbAPIData)
        }
        else if (prop == 'location_type') {
            if (!ubo.struct[prop].new_value) {
                continue;
            }
            let response = await API.uploadKYCFile(
                null,
                { 
                    ubo: JSON.stringify(uboApiData),
                    top_level_properties: JSON.stringify([{ [prop]: ubo.struct[prop].new_value}]), 
                }
            );
            if (response.status == 'success') {
                ubo.struct[prop].new_value = ''
                itemsSaved.value++;
            }
        } 
    }
}


async function Save(){
    itemsSaved.value = 0;
    if (selectedSnapshot.value) {
        store.dispatch('addNotification', {text: 'You are viewing a snapshot, please go back to the current view to save changes', type: 'error'})
    }
    if (JSON.stringify(companyDataInitial.value) != JSON.stringify(companyData.value)) {
        let success = await API.uploadSettings(companyData.value)
        store.dispatch('setValue', { key: 'companyDataInitial', value: JSON.parse(JSON.stringify(companyData.value)) })

        if (success) {
            store.dispatch('addNotification', {text: 'Company data saved successfully', type: 'success'})
        }
    }
    if ( initialCommentString.value != commentSectionString.value ) {
        let success = await API.uploadComment(JSON.stringify(commentSection.value))
        store.dispatch('setValue', { key: 'commentSectionInitial', value: JSON.parse(JSON.stringify(commentSection.value)) })
        if (success) {
            store.dispatch('addNotification', {text: 'Comments saved successfully', type: 'success'})
        }
    }
    for(let key in dataStructure.value) {
        if (key === 'ubos') {
            for (let ubo of dataStructure.value[key]) {
                await uploadUbo(ubo, null, )
            }
        }
        if (key === 'mbds') {
            for (let mbds of dataStructure.value[key]) {
                await uploadMBD(mbds)
            }
        };
        
        if (key === 'textarea') {
            if (dataStructure.value['textarea'].new_value) {
                const response = await API.uploadKYCFile(null, { textarea_value: dataStructure.value['textarea'].new_value, fileType: 'textarea' });
                if (response.status == 'success') {
                    dataStructure.value['textarea'].new_value = ''
                    itemsSaved.value++;
                }
            }
        };
        if (['cosbaof', 'scfba','subsidiaries'].includes(key)) {
            for (let file of dataStructure.value[key]) {
                const ObjectToAttach = {
                    fileType: key,
                }
                const ObjectToSend = {

                }
                let attachedFile = null

                if (file.new_file) {
                    attachedFile = file.file
                }
                if (file.subsidiary_id) {
                    ObjectToAttach['subsidiary_id'] = file.subsidiary_id
                }
                if (file.id) {
                    ObjectToAttach['id'] = file.id
                }
                if (
                    file.new_dateIssued ||
                    file.new_comment_data_handler ||
                    file.new_comment_compliance_officer ||
                    file.new_comment_supervisor ||
                    file.new_year ||
                    file.new_fiscal_year_ending_on

                ) {
                
                    if (file.new_dateIssued) {
                        ObjectToSend['dateIssued'] = file.dateIssued
                    }
                    if (file.new_comment_data_handler) {
                        ObjectToSend['comment_data_handler'] = file.comment_data_handler
                    }
                    if (file.new_comment_compliance_officer) {
                        ObjectToSend['comment_compliance_officer'] = file.comment_compliance_officer
                    }
                    if (file.new_comment_supervisor) {
                        ObjectToSend['comment_supervisor'] = file.comment_supervisor
                    }
                    if (file.new_fiscal_year_ending_on && file.fiscal_year_ending_on) {
                        ObjectToSend['fiscal_year_ending_on'] = file.fiscal_year_ending_on
                    }

                    if (file.new_year && file.year) {
                        ObjectToSend['year'] = file.year
                    }
                   
                }

                console.log('ObjectToSend', ObjectToSend)
                if (!(attachedFile || Object.keys(ObjectToSend).length > 0)) {
                    continue;
                }
                const response = await API.uploadKYCFile(
                    attachedFile, 
                    { 
                        ...ObjectToAttach,
                        ...ObjectToSend
                    }
                );
                if (response.status == 'success') {
                    itemsSaved.value++;
                    if (response.kyc_file) {
                        file.file = response.kyc_file;
                    }
                    file.new_file = false
                    file.new_dateIssued = false;
                    file.new_comment_data_handler = false;
                    file.new_comment_compliance_officer = false;
                    file.new_comment_supervisor = false;
                    file.new_year = false;
                    file.new_fiscal_year_ending_on = false;
                }
            }
        }

        const regularFileToSent = {
            fileType: key, 
        }
        let attachedRegularFiles = null
        if (
            dataStructure.value[key].new_dateIssued ||
            dataStructure.value[key].new_comment_data_handler ||
            dataStructure.value[key].new_comment_compliance_officer ||
            dataStructure.value[key].new_comment_supervisor ||
            dataStructure.value[key].new_year ||
            dataStructure.value[key].new_fiscal_year_ending_on

        ) {
            if (dataStructure.value[key].new_dateIssued) {
                regularFileToSent['dateIssued'] = dataStructure.value[key].dateIssued
            }
            if (dataStructure.value[key].new_comment_data_handler) {
                regularFileToSent['comment_data_handler'] = dataStructure.value[key].comment_data_handler
            }
            if (dataStructure.value[key].new_comment_compliance_officer) {
                regularFileToSent['comment_compliance_officer'] = dataStructure.value[key].comment_compliance_officer
            }
            if (dataStructure.value[key].new_comment_supervisor) {
                regularFileToSent['comment_supervisor'] = dataStructure.value[key].comment_supervisor
            }
            if (dataStructure.value[key].new_fiscal_year_ending_on) {
                regularFileToSent['fiscal_year_ending_on'] = dataStructure.value[key].fiscal_year_ending_on
            }
            if (dataStructure.value[key].new_year) {
                regularFileToSent['year'] = dataStructure.value[key].year
            }
        }
        //regular files
        if (dataStructure.value[key].new_file) {
            attachedRegularFiles = dataStructure.value[key].file
        }
        if ((attachedRegularFiles || Object.keys(regularFileToSent).length > 1)) {
            const response = await API.uploadKYCFile(
                attachedRegularFiles, 
                { 
                    ...regularFileToSent
                }
            );
            if (response.status == 'success') {
                if (response.kyc_file) {
                    dataStructure.value[key].file = response.kyc_file;
                }
                dataStructure.value[key].new_file = false
                dataStructure.value[key].new_dateIssued = false;
                dataStructure.value[key].new_comment_data_handler = false;
                dataStructure.value[key].new_comment_compliance_officer = false;
                dataStructure.value[key].new_comment_supervisor = false;
                dataStructure.value[key].new_year = false;
                dataStructure.value[key].new_fiscal_year_ending_on = false;
                itemsSaved.value++;
            }
        }
        
    }
    if (itemsSaved.value) {
        store.dispatch('addNotification', {text: 'Data saved successfully', type: 'success'})
    }
}

function handleStructureUpdates(event) {
    if (validationOn.value) {
        let errors = Utils.validateDataStructure(dataStructure.value);
        if (errors) {
            store.dispatch('setValue', { key: 'validationOn', value: true })
            return;
        }
        else {
            store.dispatch('setValue', { key: 'validationOn', value: false })
        }
    }

    let dataUbo = dataStructure.value['ubos'].find(el => event.index === el.id)
    dataUbo = event.ubo

    if (event.file) {
        let cell = selectedFiles.value[event.file.index] || [];
        cell.push({
            type: event.file.type,
            file: event.file.file, 
        }) 
        selectedFiles.value[event.file.index] = cell;
    }
}

function handleFileChange(event) {
    console.log('KYC VIEW event', event)
    try {
        let cell = store.state.dataStructureStore[event.fileType] 

        if (event.id && ['cosbaof', 'scfba','subsidiaries'].includes(event.fileType)) {
            cell = store.state.dataStructureStore[event.fileType].find(el => el.id === event.id)   
            if (!cell) {
                alert('error updating the files')
            }
        }
        if (event.subsidiary_id) {
            cell = store.state.dataStructureStore[event.fileType].find(el => el.subsidiary_id === event.subsidiary_id)   
            if (!cell) {
                alert('error updating the files')
            }
        }
        
        cell.file = event.file
        cell.file.filename = event.file.name
        cell.new_file = true
      

    } catch(error) {
        throw error
    }
  
}

function commentChange(event) {
    const comment_type = event.comment_type;
    if (['cosbaof', 'scfba', 'subsidiaries'].includes(event.fileType)) {
        let cell = dataStructure.value[event.fileType].find(el => el.id === event.index)
        cell[comment_type] = event.comment;
        cell[`new_${comment_type}`] = true;
        return;
    }
    if (event.subsidiary_id) {
        let cell = dataStructure.value[event.fileType].find(el => el.subsidiary_id === event.subsidiary_id)
        cell[comment_type] = event.comment;
        cell[`new_${comment_type}`] = true;
        return;
    }
    dataStructure.value[event.fileType][comment_type] = event.comment;
    dataStructure.value[event.fileType][`new_${comment_type}`] = true;
}
function changeInfo(event) {
    if (event.fileType == 'subsidiaries') {
        let cell = dataStructure.value[event.fileType].find(el => el.subsidiary_id === event.subsidiary_id)
        cell[event.event_type] = event.event_value;
        cell[`new_${event.event_type}`] = true;
        return;
    }
    dataStructure.value[event.fileType][event.event_type] = event.event_value
    dataStructure.value[event.fileType][`new_${event.event_type}`] = event.event_value
}

function handleDateChange(event) {
    dataStructure.value[event.fileType].dateIssued = event.date;
    dataStructure.value[event.fileType].new_dateIssued = true;
}

function update_ubo(event) {
    let uboIndex = dataStructure.value['ubos'].findIndex(el => event.ubo === el.id)
    let ubo = dataStructure.value['ubos'][uboIndex]
    ubo[event.field] = event.value;
    
    if (!ubo.struct.person) {
        addPersonToUBO(ubo)
    } else {
        ubo.struct.person.location_type.new_value = event.value
    }

}


function companyUpdate(value) {
    companyData.valu = value
}
function update_mbd(event) {
    if (validationOn.value) {
        let errors = Utils.validateDataStructure(dataStructure.value);
        if (errors) {
            store.dispatch('setValue', { key: 'validationOn', value: true })
            return;
        }
        else {
            store.dispatch('setValue', { key: 'validationOn', value: false })
        }
    }
    if (!event.field) {
        return;
    }
    let mbdIndex = dataStructure.value['mbds'].findIndex(el => event.mbd === el.id)
    let mbd = dataStructure.value['mbds'][mbdIndex]
    mbd[event.field].new_value = event.value;
    if (event.field == 'supervised') {
        mbd.struct.legalEntity[event.field].new_value = event.value
    }
    if (event.field == 'location_type') {
        mbd.struct.legalEntity[event.field].new_value = event.value
        
        mbd.struct.person && mbd.struct.person.forEach(el => {
            el.location_type.new_value = event.value
        })
    }


}

</script>

<style scoped lang="scss">

    .notifications {
       position: relative;

    }

    .notification-counter {
        background: #f59f9f;
        border-radius: 50%;
        padding: 5px;
        top: -2px;
        right: -20px;
        position: absolute;
        min-height: 16px;
        min-width: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .popup-content {
        position: relative;
        width: 100%;
        min-height: 150px;
    }
    .popup-close {
        position: absolute;
        right: 10px;
        top: -5px;
        cursor: pointer;
    }
    .content-popup {
        display: flex;
        justify-content: center;
        gap: 20px;
        flex: 1;
        align-items: center;


        .option-select {
            padding: 10px 20px;
            cursor: pointer;
            border: 1px solid #3c556e;
            border-radius: 5px;
        }
    }
    .popup-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 20px;
        .option-select {
            padding: 10px 20px;
            height: fit-content;
            cursor: pointer;
            border: 1px solid #3c556e;
            border-radius: 5px;
        }
    }
    .cover-comments {
        border-radius: 10px;
        padding: 20px;
        border: 1px solid var(--border-color);
        background-color: white;

        &:deep(.editor-section) {
            min-height: 50vh;
        }
    }
    .header-section {
        padding-left: 20px;
        background-color: white;
        height: 125px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    .kyc-title {
        font-weight: 600;
    }
    .kyc-header {
        max-width: 1350px;
        width: 100%;
        text-align: left;
        font-size: 28px;
        position: relative;
        color: black;

        gap: 10px;
        display: flex;
        flex-direction: column;

        .tab-views {
            overflow: auto;
            font-size: 14px;
            display: flex;
            &::-webkit-scrollbar {
                width: 4px;
                height: 4px;
            }
            &::-webkit-scrollbar-thumb {
                background: #888;
            }
            &::-webkit-scrollbar-track {
                // background: red;
            }
            .tab {
                &:first-child {
                    padding-left: 0;
                }
                white-space: nowrap;
                padding: 10px 20px;
                cursor: pointer;
                font-weight: 600;
                &.active {
                    color: var(--primary-color);
                    border-bottom: 4px solid var(--primary-color);
                }
            }
        
        }
    }
    .kyc-fields-container {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        max-width: 1350px;
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: column;
        // align-items: center;
    }
    .kyc-fields-area {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
       
    }
    .kyc-item-section {
        max-width: 1300px;
        width: 100%;
        margin-bottom: 30px;
        h3 {
            text-align: left;
        }
        &.extra-margin {
            margin-bottom: 50px;
        }
    }
    .flex {
        display: flex;
        &.row {
            flex-direction: row;
        }
        &.column {
            flex-direction: column;
        }
    }
    .passport-fields {
        margin-top: 20px;
    }
    .gap30 {
        gap: 30px;
    }
    textarea {
        resize: none; 
        box-sizing: border-box;
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        width: 685px;
        height: 150px;
    }
    .add-new {
        margin-left: auto;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .no-margin[class] {
        margin-top: 0;
        margin-bottom: 0;
    }
    .margin-top-20 {
        margin-top: 20px;
    }
    .header-of-section {
        font-size: 24px;
    }

    .snapshot-item {
        position: fixed;
        top: 60px;
        right: 0px;
        padding: 10px 20px;
        border-bottom-left-radius: 30px;
        border: 1px solid var(--border-color);
        z-index: 1001;
        font-weight: bold;
        cursor: pointer;
        background: white;
        color: black;
        padding-right: 30px;
    
    }

    .close-img {
        position: absolute;
        width: 12px;
        top: 7px;
        right: 5px;
        padding: 2px;

        cursor: pointer;
    }
    

</style>