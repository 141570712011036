<template>
<tr :id="'id' + records_id" :class="`${showInfo ? 'opened' : '' } comments-shown_${shownInputsNumber} ${!(showComplienceOfficerComment || showSupervisorComment) ? 'no-action-buttons' : ''}`">
    <td class="kyc-item-section">
        <UploaderTable 

            :key="`${index}-${fileType}-${subsidiary_id}`"
            @handleFileChange="handleFileChange" 
            :filePresent="localFilePresent"    
            :headerText="header"
            :file="localFile"  
            :title="title"
            :fileType="fileType"
            @dateInputChangeFunc="dateInputChangeFunc"
            @changeInfo="changeInfo"
            :index="index"  
            :fiscal_year_ending_on="fiscal_year_ending_on"
            :year_title="year_title"
            :year="year"
            :subsidiary_id="subsidiary_id"
            :second_line_normal="second_line_normal"
            :issueDateValue="issueDateValue"  
            :label="label"
            origin="kyc-section"
            :fullWidth="true"
        />
    </td>
    <td class="yes-no">
        <text v-if="textareaValue && localFilePresent">Y</text>
    </td>
    <td class="yes-no">
        <text v-if="!textareaValue || !localFilePresent">N</text>
    </td>

    <td class="document-comments" v-if="showAccountManagerComment && !accountManagerDisabled">
        <CommentSection 
            attrId='comment_data_handler' 
            class="markdown-content small"
            :class="{disabled: accountManagerDisabled || preview}"
            :disabled="accountManagerDisabled || preview"
            :commentSection="textareaValue"
            @changeinput="quillContent" 
        />
    </td>
    <td class="document-comments" v-if="showSupervisorComment && !supervisorDisabled">
        <CommentSection 
            attrId='comment_supervisor' 
            class="markdown-content small"
            :class="{disabled: supervisorDisabled || preview}"
            :disabled="supervisorDisabled || preview"
            :commentSection="textareaValueSupervisor"
            @changeinput="quillContent" 
        />
    </td>
    
    <td class="document-comments" v-if="showComplienceOfficerComment && !complienceOfficerDisabled">
        <CommentSection 
            attrId='comment_compliance_officer' 
            class="markdown-content small"
            :class="{disabled: complienceOfficerDisabled || preview}"
            :disabled="complienceOfficerDisabled || preview"
            :commentSection="textareaValueComplience"
            @changeinput="quillContent" 
        />
    </td>
    <td class='comment-opener' v-if="(showComplienceOfficerComment || showSupervisorComment) && !preview">
        <div class="button-holder">
            <div class='popup-comment' @click="openDialog('comment_data_handler')" v-if="showComplienceOfficerComment || showSupervisorComment">Account manager comment</div>
            <div class='popup-comment' @click="openDialog('comment_data_processor')" v-if="showComplienceOfficerComment">Supervisor comment</div>
            <div class='popup-comment' @click="openDialog('comment_compliance_officer')" v-if="['EXTERNAL_AUDITOR', 'INTERNAL_AUDITOR', 'TRUST_OFFICER'].includes(user.type)">Compliance manager comment</div>
            <div class='popup-comment' @click="openDialog('all')" v-if="showComplienceOfficerComment">All Comments</div>
            <div class='popup-comment' @click="openDialog('proposedChanges')" v-if="proposedChanges && proposedChanges.length">Approval needed</div>
        </div>
    </td>
    <td style="padding: 0;"></td>


    <PopupCover v-if="showInfo" @close="closePopup">
        <TableRowDialog 
            @close="closePopup"
            @InfoUpdate="InfoUpdate"
            :headerText="header"
            :commentsToShow="commentsToShow"
            :proposedChanges="proposedChanges"
            :fileType="fileType"
            :comment_data_handler_value="textareaValue"
            :comment_supervisor_value="textareaValueSupervisor"
            :comment_compliance_officer_value="textareaValueComplience"
            :title="'Delete Document '"
            :message="'Are you sure you want to delete this Document?'"
        >
        </TableRowDialog>
    </PopupCover>

</tr>

</template>
<script setup>
import { ref, defineEmits, computed, withDefaults } from 'vue'
import PopupCover from '@/components/PopupCover.vue'
import TableRowDialog from '@/dialogs/TableRowDialog.vue'

import CommentSection from '@/views/CommentSection.vue';

import InpComponent from '@/components/InpComponent.vue'
import UploaderTable from '@/components/UploaderTable.vue'

import API from '../api/index.js'
import store from '../store';

const emit = defineEmits(['handleFileChange', 'dateInputChangeFunc', 'commentChange', 'changeInfo' ],)
const user = computed(() => store.state.user);

const showInfo = ref(false)

const showAccountManagerComment = ref(false);
const showSupervisorComment = ref(false);
const showComplienceOfficerComment = ref(false);
const shownInputsNumber = ref(1);

const accountManagerDisabled = ref(true);
const supervisorDisabled = ref(true);
const complienceOfficerDisabled = ref(true);

if (user.value && user.value.type == 'DATA_CONTROLLER') {
    showAccountManagerComment.value = true
    accountManagerDisabled.value = false
}
if (user.value && user.value.type == 'DATA_PROCESSOR') {
    showAccountManagerComment.value = true
    showSupervisorComment.value = true;
    supervisorDisabled.value = false
}
if (['COMPLIANCE_OFFICER', 'EXTERNAL_AUDITOR', 'INTERNAL_AUDITOR', 'TRUST_OFFICER'].includes(user.value.type)) {
    showComplienceOfficerComment.value = true
    showAccountManagerComment.value = true
    showSupervisorComment.value = true
}
if (user.value.type == 'COMPLIANCE_OFFICER') {
    complienceOfficerDisabled.value = false
}

function closePopup(event){
    showInfo.value = false;
}

const props = defineProps([
  'header',
  'title', 
  'fileType', 
  'filePresent', 
  'issueDateValue', 
  'error', 
  'file',
  'fiscal_year_ending_on',
  'year_title',
  'id',
  'subsidiary_id',
  'second_line_normal',
  'label', 
  'proposedChanges',
  'index',
  'year',
  'comment_data_handler_value',
  'comment_supervisor_value',
  'comment_compliance_officer_value',
  'preview'

  ])


const localFile = ref(props.file)
const localFilePresent = ref(props.filePresent)

const commentsToShow = ref(null)

function dateInputChangeFunc(event) {
  emit('dateInputChangeFunc', event)
}
function handleFileChange(event) {
    localFile.value = event.file
    localFilePresent.value = event.file
    console.log('TABLE FILE ROW handleFileChange', event)
    emit('handleFileChange', event)
}
function changeInfo(event) {
    emit('changeInfo', event)
}
const textareaValue = ref(props.comment_data_handler_value)
const textareaValueComplience = ref(props.comment_compliance_officer_value)
const textareaValueSupervisor = ref(props.comment_supervisor_value)

function quillContent(event) {
    if (event.id == 'comment_data_handler') {
        textareaValue.value = event.structure
    }
    if (event.id == 'comment_supervisor') {
        textareaValueSupervisor.value = event.structure
    }
    if (event.id == 'comment_compliance_officer') {
        textareaValueComplience.value = event.structure
    }
    emit('commentChange', {
        comment: JSON.stringify(event.structure),
        fileType: props.fileType,
        comment_type: event.id,
        index: props.index,
        subsidiary_id: props.subsidiary_id
    })
    console.log('quillContent', event)
}

function InfoUpdate(event) {
    if (event.type == 'comment_data_handler') {
        textareaValue.value = event.value
    }
    if (event.type == 'comment_supervisor') {
        textareaValueSupervisor.value = event.value
    }
    if (event.type == 'comment_compliance_officer') {
        console.log('comment_compliance_officer', event.value)

        textareaValueComplience.value = event.value
    }
    emit('commentChange', {
        comment: event.value,
        fileType: props.fileType,
        comment_type: event.type,
        index: props.index,
        subsidiary_id: props.subsidiary_id
    })
}
function openDialog(comment_type) {
    console.log('openDialog', comment_type)
    commentsToShow.value = comment_type
    if (showInfo.value) {
        return
    }
    showInfo.value = true;
}

const records_id = computed(() => 
    props.file ? props.file.id : props.id
)
</script>
<style lang="scss" scoped>
    textarea {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        padding: 15px;
        resize: none;
        box-sizing: border-box;

        &:focus {
            outline: none;
            border: 1px solid var(--primary-color);
        }
    }
   
    .data-holder-comment-section {
        font-size: 12px;
        font-weight: 600;
        margin-top: 20px;
        
        text-align: left;
    }
    .no-margin {
        margin-top: 0px;
    }
    .popup-comment {
        font-size: 12px;
        padding: 5px;
        color: white;
        background-color: var(--primary-color);
        border-radius: 5px;
        text-align: center;
        font-weight: bold;
        cursor: pointer;
        transition: all 0.3s;
   
        &:hover {
            background: #00408985;
        }
    }
    .button-holder {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 200px;
    }
    .data-holder-comment {
        height: 100%;
        min-height: 120px;
        width: 100%;
        border: 1px solid #e0e0e0;
        border-radius: 10px;
        border-width: 1.5px;
        margin-top: 10px;
    }
    .document-comments {
        position: relative;
    }
    .kyc-item-section {
        position: relative;

        // vertical-align: bottom;
    }

    td {
        padding: 10px;
        // border: 1px solid var(--border-color);
    }
    h3 {
        color: black;
        text-align: left;
        margin: 0px;
        font-size: 16px;
    }

    tr.comments-shown_1 {
        .document-comments {
            width: 45%;
            height: 100%;
        }
    }
    tr.no-action-buttons {
        .document-comments {
            width: 60%;
        }
    }

    tr.comments-shown_2 {
        .document-comments {
            width: 33%;
            height: 100%;
        }
    }

    tr.comments-shown_3 {
        .document-comments {
            width: 20%;
            height: 100%;
        }
    }

    .yes-no {
        width: 20px;
        color: black;
        font-size: 16px;
        font-weight: 600;
    }
    
    tr > td:first-child {
        min-width: 33%;
        width: 33%;
    }
    tr {
        border-top: 1.5px solid var(--border-color);
    }
    .big {
        height: 100%;
        padding-top: 15px;
        box-sizing: border-box;
        &:deep(.drop-area) {
            height: 100%;
        }
    }
    tr:not(.opened) {
        &:hover {
            .document-opener {
                width: 40px;
                right: -40px;
            }
        }
    }
    .document-opener {
        position: absolute;
        transition: all 0.35s;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 0;
        background-color: var(--primary-color);
        cursor: pointer;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
            text-orientation: sideways-right;
            writing-mode: vertical-rl;
            color: white;
            font-weight: 600;
            font-size: 16px;
            text-align: center;
        }
    }
    .markdown-content {
        height: 100%;
        .small {
            max-height: 200px;
        }
    }

</style>