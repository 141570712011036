<template>
    <div class="main-section-wrapper">
      <div class="wrapper-of-uploade">
        <div class="flex column gap-10">
          <div class="text" v-if="title"> 
            <div 
              v-for="(part, index) in titleParts" 
              :class="`
                title ${props.second_line_normal ? 'second_line_normal' : ''} 
                title-len${titleParts.length} 
                title-${index}`">
              {{ part }}
            </div>
          </div>
          <div class="additinal-info flex row ">
            <div v-if="yearPresent">
              <InpComponent 
                  type="options"
                  :options="yearOptions"  
                  theme="light" 
                  :origin="origin"
                  :label="year_title"
                  key_ref="year"
                  :disabled="disabled"
                  :value="localYear" 
                  @inputChange="inputChangeFunc"
                  :error="year_error"
              />
            </div>
            <div v-if="fiscal_year_ending_on_present" class="date-input">
              <InpComponent 
                  type="date"
                  theme="light" 
                  class="input-fiscal-year"
                  label="Fiscal Year Ending On"
                  key_ref="fiscal_year_ending_on"
                  :disabled="disabled"
                  :origin="origin"
                  :value="localFiscalYear" 
                  @inputChange="inputChangeFunc"
                  :error="fiscal_year_ending_on_error"
              />
            </div>
          </div>
        </div>
      </div>
      <div v-if="issueDatePresent" class="styled-input" :class="error ? 'error-present': ''">
        <InpComponent 
          type="date"
          theme="light" 
          :label="label"
          :origin="origin"
          key_ref="issueDateValue"
          :value="issueDateValue" 
          @inputChange="inputChangeFunc"
          :error="year_error"
        />
      </div>

      <div
        class="drop-area"

        @dragover.prevent="dragOver"
        @dragleave.prevent="dragLeave"
        @drop.prevent="dropFile"
        @click="canUpload && $refs.fileInput.click()"
        :class="{ 'drag-over': isDragOver, 'full-width': fullWidth, 'long-header' : headerText && headerText.length > 50, 'canUpload': canUpload }"
        >
        <span v-if="headerText" class="header" :class="{'long' : headerText && headerText.length > 50}">
            <i>{{ headerText }}</i>
        </span>

          <div class="more-actions" v-if="localFile" @click="eventClick">
              <img src="@/assets/download.svg" @click="Download" class="download">
              <img src="@/assets/delete.svg" v-if="!disabled" @click="DeleteFileConfirm" class="delete">
              <img src="@/assets/password-look.svg" @click="openFile" class="delete">
            </div>
          <div class='upload-section' v-if="localFile">
            <img src="@/assets/upload.svg" class="image-upload"/>
           <span> Attached file: <b><i>{{ localFile.filename }}</i></b></span>
          </div>
          <div class="upload-section" v-else-if="!selectedFile && canUpload">

            <img src="@/assets/upload.svg" class="image-upload"/>
            <span>Drag and drop file here to upload</span>
            <button class="flex justify-center transparent button">
              <img src="@/assets/plus.svg" class="plus"/>
                Attach File
            </button>
          </div>
          <p v-else-if="selectedFile">Selected File: {{ selectedFile.name }}</p>
          <p v-else>File is not attached</p>
          <input v-if="canUpload" type="file" ref="fileInput" accept=".pdf" style="display: none" @change="handleFileChange" />
      </div>
      <PopupCover v-if="deleteObject" @close="() => deleteObject = !deleteObject">
        <ConfirmationPopup 
          @close="popupConfirm"
          :title="'Delete Document '"
          :message="'Are you sure you want to delete this Document?'"
        />
      </PopupCover>
    </div>

</template>

<script setup>
import { ref, defineEmits, computed, withDefaults } from 'vue'
import InpComponent from '@/components/InpComponent.vue'

import API from '../api/index.js'
import store from '../store';
import PopupCover from './PopupCover.vue'
import ConfirmationPopup from './ConfirmationPopup.vue'
const user = computed(() => store.state.user);
const viewingToken = computed(() => store.state.viewingToken);

const emit = defineEmits(['handleFileChange', 'dateInputChangeFunc', 'changeInfo' ],)
const deleteObject = ref(false);

const selectedFile = ref(null)
const props = defineProps([
  'title', 
  'fileType', 
  'filePresent',
  'issueDateValue', 
  'error', 
  'file',
  'headerText',
  'subsidiary_id',
  'fiscal_year_ending_on',
  'second_line_normal', 
  'fullWidth',
  'label', 
  'index', 
  'year', 
  'metaTada',
  'origin',
  'disabled'
])
const titleParts = computed(() => props.title && props.title.split('\n'));
const isDragOver = ref(false)

const fiscal_year_ending_on_error = ref('')
const year_error = ref('')
const filePresentLocal = ref(props.filePresent)
const localFile = ref(props.file)


const issueDatePresent = computed(() => {
  return [
    'kyc-feeibtbvdna-3',
    'kyc-lteabibtdoea-4',
    'kyc-mlibtdoea-4-1',
    'kyc-attaoi-5-1',
    'kyc-reftcoc-5-2',
  ].includes(props.fileType)

})

const yearPresent = computed(() => {
  return [
    'kyc-aa-y1-12',
    'kyc-aa-y2-12',
    'kyc-dogmos-13',
    'kyc-fpt-14',
    'subsidiaries',
  ].includes(props.fileType)
})

const year_title = computed(() => {
  let year_titles = {
      'kyc-aa-y2-12': 'Year 2',
      'kyc-aa-y1-12': 'Year 1',
      'subsidiaries': 'Annual accounts year',
  }
  return year_titles[props.fileType] || 'Year'
})

const fiscal_year_ending_on_present = computed(() => {
  return [
    'kyc-aa-y1-12',
    'kyc-aa-y2-12',
  ].includes(props.fileType)
})


function dragOver() {
  if (!canUpload.value) {
    return;
  }
  isDragOver.value = true;
}
 
const canUpload = computed(() => {
  return ['DATA_CONTROLLER', 'CLIENT'].includes(user.value.type)
})

const localFiscalYear = ref(props.fiscal_year_ending_on)
const localYear = ref(props.year)

function dragLeave() {
    if (!canUpload.value) {
      return;
    }
    isDragOver.value = false;
}
function dropFile(event) {
    if (!canUpload.value) {
      return;
    }
    isDragOver.value = false;
    handleFileChange(event);
}
function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    
    if (event_type == 'issueDateValue') {
      dateInputChangeFunc({target: {value: event_value}})
      return;
    }
    if (event_type == 'year') {
      localYear.value = event_value
    }
    if (event_type == 'fiscal_year_ending_on') {
      localFiscalYear.value = event_value
    }


    let year_val = localYear.value;
    let fiscal_year_ending_on_val = localFiscalYear.value;
    if (year_val && fiscal_year_ending_on_val) {
      if (+year_val > +fiscal_year_ending_on_val.slice(0, 4) &&  +year_val != +fiscal_year_ending_on_val.slice(0, 4)) {
        fiscal_year_ending_on_error.value = 'Years do not match';
        year_error.value = 'Years do not match';
        return;
      }
    }
    fiscal_year_ending_on_error.value = '';
    year_error.value = '';
   
    emit('changeInfo', {
      event_type: 'year', 
      event_value: localYear.value, 
      fileType: props.fileType,
      subsidiary_id: props.subsidiary_id,
    })

    emit('changeInfo', {
      event_type: 'fiscal_year_ending_on', 
      event_value: localFiscalYear.value, 
      fileType: props.fileType,
      subsidiary_id: props.subsidiary_id,

    })

}

function eventClick(event) {
  event.stopPropagation();
}
const yearOptions = computed(() => store.state.yearOptions);




function handleFileChange(event) {
  const files = event.target.files || event.dataTransfer.files;
  
  if (files.length > 0) {
      selectedFile.value = files[0];
  }
  console.log('UPLOADER TABLE handleFileChange', files[0])
  emit('handleFileChange', {
    file: files[0], 
    fileType: props.fileType, 
    id: props.index,
    subsidiary_id: props.subsidiary_id
  })
}



function dateInputChangeFunc(event) {
  emit('dateInputChangeFunc', {date: event.target.value, fileType: props.fileType})
}


function DeleteFileConfirm() {
  console.log('aaaaa')
  deleteObject.value = true;
}


async function popupConfirm(event){
  deleteObject.value = false;

  if (!event) {
    return
  }
  
  const result = await API.deleteObject(localFile.value.id, 'kyc_file', filePresentLocal.value.filename + 'file');

  if (result) {
    // store.dispatch('addNotification', {text: 'Request to delete ' + filePresentLocal.value.filename + ' has been sent', type: 'success'})
    // selectedFile.value = null;
    // filePresentLocal.value = null;
    // localFile.value = null;
  }
}

async function openFile(event) {
  await API.generateViewToken();
  event.stopPropagation();

  window.open(`${API.API_URL}get-kyc-file/?id=${localFile.value.serverName}&viewingToken=${viewingToken.value}`, '_blank');
}

async function Download(event) {
  console.log('Download', event)
  event.stopPropagation();
  await API.DownloadKYCFile(localFile.value.serverName, localFile.value.filename);
}


</script>
<style scoped lang="scss">
  .delete {
    width: 30px;
    height: 30px;
    padding: 5px;
    box-sizing: border-box;
    border-radius: 4px;
    bottom: 0px;
    &:hover {
      cursor: pointer;
      background-color: var(--app-background);
      outline: 1px solid var(--border-color);
    }
  }
  .download {
    width: 30px;
    height: 30px;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 4px;
    &:hover {
      cursor: pointer;
      background-color: var(--app-background);
      outline: 1px solid var(--border-color);

    }
  }
  .image-upload {
    width: 30px;
    height: 30px;
  }
  .header {
      position: absolute;
      background: var(--app-background);
      border-radius: 5px;
      border: 1px solid var(--border-color);
      padding: 0 5px;
      left: -1.5px;
      border-bottom-left-radius: 0;
      color: black;
      font-weight: 600;
      text-align: left;
      max-width: 86%;
      top: -13px;
      font-size: 14px;
      z-index: 2;
    &.long {
      top: -35px;
    }
  }

  .drop-area {
    position: relative;
    width: 335px;
    min-width: 300px;
    height: 140px;
    margin-top: 10px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8997A5;
    border-radius: 5px;
    border-radius: .5rem;
    border-style: dashed;
    border-width: 2px;
    border-color: rgb(224 226 236);
    cursor: pointer;
    transition: all 0.3s;
    
    &.canUpload:hover {
      border-color: var(--primary-color);
      font-size: 15.5px;
      .header {
        border-color: var(--primary-color);

      }
    }
  }

  .button {
    width: fit-content;
    color: var(--primary-color);
  }
.margin-top-10 {
  margin-top: 10px;
}
.upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding-right: 20px;
}
.input-fiscal-year {
  width: fit-content;
}
.buttons {
  height: 37px;
}
.plus {
  width: 15px;
  height: 15px;
  margin-right: 10px;

  :hover {
    background: unset;
    cursor: pointer;
  }
}
.margin-div {
  display: flex;
  gap: 10px;
  button {
    flex: 1;
  }
}

.main-section-wrapper {
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.wrapper-of-uploade {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
  justify-content: flex-end;
}

.area-width {
  width: 335px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  img {
      margin-right: 10px;
  }
}
.title {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
.title-0.title-len2 {
  margin: 0;
}

.title-1.title-len2:not(.second_line_normal) {
  font-size: 16px;
  color: black;
}

.styled-input {
    display: flex;
    flex-direction: column;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #3D5873;
    text-align: left;
  }
  
  input, select {
    background: #38314e;
    border: 1px solid #2b263a;
    outline: none;
    caret-color: #2b263a;
    color: white;
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 16px;
    height: 22px;
    border-top: unset;
    border-left: unset;
    border-right: unset;
    border-radius: 0;
    transition: padding 0.3s;
    &.select-elem {
      width: 100%;
      height: 30px;
    }
    &:focus {
      padding-bottom: 10px;
    }
    padding-left: 0;
  }
  .light {
    background: unset;
    color: black;
    caret-color: black;
  }
  

  .error-present {
    input, select {
      border-color: red;
      border-width: 1.5px;
    }
  }
  .light_label {
    font-size: 13px;
    margin-bottom: 0;

  }
  .error {
    color: red;
    text-align: left;
    padding-left: 14px;
  }

  .full-width[class] {
    width: 100%;
  }
  .additinal-info {
    display: flex;
    gap: 30px;
    > * {
      flex: 1;
      &.date-input {
        flex: 2;
      }
    }
  }
  .more-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

}
.more-actions {
  position: absolute;
  top: 0px;
  right: 0px;

  div {
    cursor: pointer;

    color: black;
  }
}
.long-header[class] {
  margin-top: 32px;
}
</style>