<template>
    <div v-if="props.companyData">
        <!-- {{ props.companyData.next_review_date }} -->


     

        <div class="box">
            <div class="timeline"></div>
            <div class="dateElement top start c2">
                <div class="line"></div>
                <div class="label over">{{ getDate(minDate) }}</div>
            </div>
            <div class="dateElement top end c2" v-if="maxDate != props.companyData?.next_review_date">
                <div class="line"></div>
                <div class="label over">{{ getDate(maxDate) }}</div>
            </div>
            <div class="dateElement top c2" :style="{
                ...getRelativeDate(new Date())
                
                }">
                <div class="line"></div>
                <div class="label over w-140">Today {{ getDate(new Date()) }}</div>
            </div>

            <div v-if='props.companyData.next_review_date' class="dateElement top c2" :style="getRelativeDate(props.companyData.next_review_date)">
                <div class="line"></div>
                <div class="label over w-140">Review Date {{ getDate(props.companyData.next_review_date) }}</div>
            </div>
            <div v-for="month in monthsDates" class="dateElement top c2" :style="getRelativeDate(new Date(month))">
                <div class="line"></div>
                <div class="label over w-140">{{ getDate(month) }}</div>
            </div>
            
            
            <div v-for="person in people" v-if="people" class="person-el">


                    

                <div v-if="person.person.passport_file.dateExpiry?.value" 
                :title="`
Passport Document 
Name: ${person.person.passport_file.firstName?.value} ${person.person.passport_file.lastName?.value}
Issued: ${getDate(person.person.passport_file.dateIssued.value)}
Expires: ${getDate(person.person.passport_file.dateExpiry.value)}
                `"
                    class="dateElement bar c2" 
                    :style="{
                        ...getRelativeDate(person.person.passport_file.dateIssued.value),
                        ...getDuration(person.person.passport_file.dateIssued.value, person.person.passport_file.dateExpiry.value)
                    }"
                    :class="isAboutToExpire(person.person.passport_file.dateIssued.value)">
                    <div class="notification-period"
                        :style="{
                            ...getDuration(addDays(person.person.passport_file.dateExpiry.value, -14), person.person.passport_file.dateExpiry.value)
                        }"></div>
                    <div class="label-doc"> Passport </div>
                </div>


                <div v-if="person.person.secondPassport_file.secondDateExpiry?.value && person.person.location_type.value == 'abroad'" 
                    class="dateElement bar c4" 
                    :style="{
                        ...getRelativeDate(person.person.secondPassport_file.secondDateIssued.value),
                        ...getDuration(person.person.secondPassport_file.secondDateIssued.value, person.person.secondPassport_file.secondDateExpiry.value)

                    }" 
                    :class="isAboutToExpire(person.person.secondPassport_file.secondDateExpiry.value)">
                    <div class="label-doc"> Second Passport </div>
                 
                </div>
                
                <div v-if="person.person.address_file.address_date_issues?.value" 
                    class="dateElement bar c6" 
                        :style="{
                            ...getRelativeDate(person.person.address_file.address_date_issues.value),
                            ...getDuration(
                                person.person.address_file.address_date_issues.value, 
                                addMonth(person.person.address_file.address_date_issues.value, 3)
                            )
                        }">
                    <div class="label-doc"> Address File </div>
                </div>
                
                <!-- -->
                <div v-if="person.person.address_file.address_date_issues?.value" 
                    class="dateElement bar c6 half-transparent"
                        :style="{
                            ...getRelativeDate(addMonth(props.companyData?.next_review_date, -3)),
                            ...getDuration(
                                addMonth(props.companyData?.next_review_date, -3),
                                props.companyData?.next_review_date,
                            )
                        }">
                </div>
                <!--  -->
            </div>
        </div>

    </div>
</template>

<script setup>
import { ref, defineEmits, onMounted, defineProps, watch } from 'vue' 
const props = defineProps(['companyData','dataStructure'])
const people = ref(null)
import dayjs from 'dayjs'

people.value = [...props.dataStructure.ubos.map((ubo) => {
    let person = ubo.struct.person 
    return {
        origin: 'ubo',
        person
    }
})]

const maxDate = ref(new Date())
const minDate = ref(new Date())
const monthsDates = ref([])

watch(people, (oldValue, newPeople) => {
    if (newPeople > 0) {
        console.log("People variable is not empty");
        setMinMaxValues();
    }
});
onMounted(() => {
    setMinMaxValues()
})



function getRelativeDate(date_) {
    console.log('getRelativeDate date', date_);
    let date = new Date(date_)

    let min = minDate.value
    let max = maxDate.value
    let diff = max - min
    let dateDiff = date - min
    let percent = dateDiff / diff * 100
    return {
        left: percent + '%'
    }
}

function addDays(date, days) {
    let newDate = new Date(date)
    newDate.setDate(newDate.getDate() + days)

    return newDate
}

function addMonth(date, months) {
    let newDate = new Date(date)
    newDate.setMonth(newDate.getMonth() + months)
    return newDate
}
function getDuration(start, end) {
    let startDate = new Date(start)
    let endDate = new Date(end)
    let diff = endDate - startDate

    let min = minDate.value
    let max = maxDate.value
    let diffRange = max - min
    console.log('diffRange', {diffRange, diff})
    let percent = diff / diffRange * 100
    return {
        width: percent + '%'
    }


}
function isAboutToExpire(date) {
    let today = new Date()
    let expiry = new Date(date)
    let diff = expiry - today
    if (diff < 0) {
        return 'expired'
    }
    if (diff < 1000 * 60 * 60 * 24 * 14) {
        return 'expiring'
    }
    return ''
}
function getDate(date) {
    return dayjs(date).format('MMM D, YYYY')
}
function setMinMaxValues() {
    if (!people.value) return
    for (let person of people.value) {
        if (person.person.passport_file.dateExpiry.value) {
            let date = new Date(person.person.passport_file.dateExpiry.value);
            console.log('date', date, maxDate.value)
            if (date > maxDate.value) {
                maxDate.value = date
            }
            if (date < minDate.value) {
                minDate.value = date
            }
        }
        if (person.person.passport_file.dateIssued.value) {
            let date = new Date(person.person.passport_file.dateIssued.value)
            if (date > maxDate.value) {
                maxDate.value = date
            }
            if (date < minDate.value) {
                minDate.value = date
            }
        }

        if (person.person.passport_file.dateIssue?.value) {
            let date = new Date(person.person.passport_file.dateIssue.value)
            if (date > maxDate.value) {
                maxDate.value = date
            }
            if (date < minDate.value) {
                minDate.value = date
            }
        }
        if (person.person.secondPassport_file.secondDateIssued?.value) {
            let date = new Date(person.person.secondPassport_file.secondDateIssued.value)
            if (date > maxDate.value) {
                maxDate.value = date
            }
            if (date < minDate.value) {
                minDate.value = date
            }
        }
        if (person.person.secondPassport_file.secondDateExpiry?.value) {
            let date = new Date(person.person.secondPassport_file.secondDateExpiry.value)
            if (date > maxDate.value) {
                maxDate.value = date
            }
            if (date < minDate.value) {
                minDate.value = date
            }
        }
        if (person.person.secondPassport_file.secondateIssue?.value) {
            let date = new Date(person.person.secondPassport_file.secondateIssue.value)
            if (date > maxDate.value) {
                maxDate.value = date
            }
            if (date < minDate.value) {
                minDate.value = date
            }
        }
        if (person.person.address_file.address_date_issues?.value) {
            let date = new Date(person.person.address_file.address_date_issues.value)
            if (date > maxDate.value) {
                maxDate.value = date
            }
            if (date < minDate.value) {
                minDate.value = date
            }

            let month3fromnow = addMonth(date, 3)
            if (month3fromnow > maxDate.value) {
                maxDate.value = month3fromnow
            }
        }

    }
    if (props.companyData?.next_review_date){
        if (new Date(props.companyData.next_review_date) > maxDate.value) {
            maxDate.value = new Date(props.companyData.next_review_date)
        }
        if (new Date(props.companyData.next_review_date) < minDate.value) {
            minDate.value = new Date(props.companyData.next_review_date)
        }
    }
    // if (props.companyData?.previous_review_date){
    //     if (new Date(props.companyData.previous_review_date) > maxDate.value) {
    //         maxDate.value = new Date(props.companyData.previous_review_date)
    //     }
    //     if (new Date(props.companyData.previous_review_date) < minDate.value) {
    //         minDate.value = new Date(props.companyData.previous_review_date)
    //     }
    // }
    const start = new Date(minDate.value.getFullYear(), minDate.value.getMonth(), 1);
    const end = new Date(maxDate.value.getFullYear(), maxDate.value.getMonth() + 1, 0);

    let currentDate = start;
    while (currentDate <= end) {
        if (currentDate > minDate.value && currentDate < maxDate.value) {
            monthsDates.value.push(new Date(currentDate));
        }
        currentDate.setMonth(currentDate.getMonth() + 1);
    }
    minDate.value.setDate(minDate.value.getDate() - 2);

}



// ...props.dataStructure.mbds.map((ubo) => {
//     let person = ubo.struct.person 
//     return {
//         origin: 'ubo',
//         person
//     }
// }),


</script>





<style lang="scss" scoped>


.box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    height: fit-content;
    position: relative;
}

.timeline {
    position: absolute;
    width: 100%;
    top: 40px;
    height: 2px;
    background-color:black;
}

.notification-period {
    position: absolute;
    height: 30px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(173, 3, 3);
    opacity: 0.5;
    right: 0;
}



.dateElement {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    &.bar {
        height: 40px;
        background-color: #003F89;
        border-radius: 5px;
    } 
    &.half-transparent[class] {
        opacity: 0.5;
        background-color: gray;
    }  
    &.top {
        top: 10px;
    }
    &.expiring {
        .line {
            background-color: rgb(173, 3, 3);
            
        }
        color: rgb(173, 3, 3);
    }
    &.expired {
        .line {
            background-color: red
        }
        color: red
    }
}

.c2 {
    &.bar {
        height: 30px;
        top: 40px;
        background-color: #003F89;
    }

    .line {
        height: 30px;
        background-color: black;
    }
    .over {
        bottom: 0px;
    }
    .under {
        top: 30px;
    }
}
.c4 {
    &.bar {
        height: 30px;
        top: 80px;
        background-color: #003F89;
    }

    .line {
        height: 80px;
        background-color: black;
    }
    .over {
        bottom: 20px;
    }
    .under {
        top: 80px;
    }
}
.c6 {
    &.bar {
        height: 30px;
        top: 120px;
        background-color: #003F89;
    }
}
.line {
    width: 2px;
    position: absolute;
}
.label {
    position: absolute;
    width: 55px;
    padding: 2px 0;
    font-size: 12px;
    transform: translateX(-50%);
}
.label-doc {
    position: absolute;
    background-color: black;
    color: white;
    font-size: 12px;
    padding: 4px 10px;
    top: 4px;
    border-radius: 3px;
    transform: translateX(-20%);
}
.w-140 {
    width: 90px;
}
.w-180 {
    width: 140px;
}
.start {
    left: 0;
}
.end {
    right: 0;
}

.person-el {
    width: 100%;
    position: relative;
    margin-bottom: 10px;
}

</style>