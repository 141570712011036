<template>
    <div class="history-management-view">
        <div class="documentmanagement-header">Review History</div>
        <DataSpanshot />
    </div>
</template>

<script setup>
    import { ref, computed, onMounted, watch, defineEmits } from 'vue'
    
    import CompanyAcounts from '@/components/CompanyAcounts.vue'
    import DataSpanshot from '@/components/DataSpanshot.vue'
    import API from '../api/index.js'
    import store from '@/store/index.js';

    onMounted(async() => {
        let data = await API.getSnapshotList();
        store.dispatch('setValue', { key: 'labelYear', value: data.snapshots });

    })
</script>

<style lang="scss" scoped>

    .documentmanagement-header {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 20px;
        text-align: left;
        color: black;
        padding-top: 20px;
    }
    .history-management-view {
        display: flex;
        padding: 20px;
        padding-top: 60px;
        flex-direction: column;
    }

</style>