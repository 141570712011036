<template>
    <div class="kyc-fields-area mb-40">
        <div class="flex full-width gap-40">
            <div class="section flex-3">
                <h3>Company Data</h3>
                <div class="flex gap-40 full-width">
                    <div class="flex column flex-1">
                        <InpComponent 
                            type="text" 
                            theme="light"
                            light_label="true"
                            key_ref="name"
                            :value="companyData.name" 
                            @inputChange="inputChangeFunc"
                            label="Name"/>
                        <InpComponent 
                            type="text" 
                            theme="light"
                            light_label="true"
                            key_ref="chamber_of_commerce"
                            :value="companyData.chamber_of_commerce" 
                            @inputChange="inputChangeFunc"
                            label="Chamber of Commerce"/>
                        <InpComponent 
                            type="text" 
                            theme="light"
                            light_label="true"
                            key_ref="activity"
                            :value="companyData.activity" 
                            @inputChange="inputChangeFunc"
                            label="Activity"
                        />
                        <div class="inner-section risk-section flex-1 border-rounded-gray">
                            <h3 class="header">Risk Classification</h3>
                            <div class="flex row sections">
                                <div class="flex">
                                    <input type="radio" id="low" name="risk_level" value="low" :checked="localCompanyData.risk_level == 'low'" @change="handleChange('risk_level', 'low')">
                                    <label for="low">Low</label>
                                </div>
                                <div class="flex">
                                    <input type="radio" id="medium" name="risk_level" value="medium" :checked="localCompanyData.risk_level == 'medium'" @change="handleChange('risk_level', 'medium')">
                                    <label for="medium">Medium</label>
                                </div>
                                <div class="flex">
                                    <input type="radio" id="high" name="risk_level" value="high" :checked="localCompanyData.risk_level == 'high'" @change="handleChange('risk_level', 'high')">
                                    <label for="high">High</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1">
                        <AddressComp
                            key="company-address"
                            class="full-width address"
                            :address="localAddress"
                            @addressChange="changeAddress">
                        </AddressComp>

                    </div>
                </div>
            </div>
          
            <ChartComponent
                :dataStructure="props.dataStructure"
                class="flex-2 flex justify-center align-center"
                style="height: 450px; width: 450px;"
            ></ChartComponent>
        </div>

            <div class="section full-width">
                <div class="flex row gap-40">
                    

                    <div class="inner-section flex-1 mb-20 kyc-review-date border-rounded-gray">
                        <h3>Kyc Review</h3>
                        <div class="flex column">
                            <InpComponent 
                                type="date" 
                                theme="light"
                                label="Date previous compliance review"
                                key_ref="previous_review_date"
                                :value="localCompanyData?.previous_review_date" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent 
                                type="date"  
                                theme="light"
                                :disabled="localCompanyData?.next_review_date_type != 'manual'"
                                :label="`Date proposed compliance review ${localCompanyData?.next_review_date_type != 'manual' ? '(calculated automatically)' : ''}`"
                                key_ref="next_review_date"

                                :value="localCompanyData?.next_review_date" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent 
                                type="date" 
                                theme="light" 
                                label="Date final compliance review"
                                key_ref="date_final_compliance_review"
                                :value="localCompanyData?.date_final_compliance_review" 
                                @inputChange="inputChangeFunc"
                            />
                        </div>
                    </div>



                    <div class="inner-section flex-1 border-rounded-gray">
                        <h3 class="">Annual Accounts</h3>
                        <div class="flex column">
                            <div class="label-like"> Annual accounts confirm calendar year</div>
                            <div class="flex row gap-10 mb-15" >
                                <div class="flex align-center justify-center">
                                    <input 
                                        type="radio" 
                                        id="YES" 
                                        style="margin: 0 5px"
                                        name="annual_account_conform_calendar_year"
                                        value="YES" 
                                        :checked="localCompanyData?.annual_account_conform_calendar_year == 'YES'" 
                                        @change="handleChange('annual_account_conform_calendar_year', 'YES')"
                                    >
                                    <label for="YES">YES</label>
                                </div>
                                <div class="flex">
                                    <input 
                                        type="radio" 
                                        id="NO" 
                                        style="margin: 0 5px"
                                        name="annual_account_conform_calendar_year"
                                        value="NO" 
                                        :checked="localCompanyData?.annual_account_conform_calendar_year == 'NO'" 
                                        @change="handleChange('annual_account_conform_calendar_year', 'NO')"
                                    >
                                    <label for="NO">NO</label>
                                </div>
                            </div>
                            <InpComponent 
                                type="options"
                                :options="yearOptions"  
                                theme="light"
                                label="Current fiscal year"
                                key_ref="current_fiscal_year"
                                :value="localCompanyData?.current_fiscal_year" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent 
                                v-if="localCompanyData?.annual_account_conform_calendar_year == 'NO'"
                                type="date"
                                theme="light" 
                                label="Fiscal Year runs from"
                                key_ref="fiscal_year_starts_on"
                                :value="localCompanyData?.fiscal_year_starts_on" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent 
                                v-if="localCompanyData?.annual_account_conform_calendar_year == 'NO'"
                                type="date"
                                theme="light" 
                                label="Fiscal Year runs up till"
                                key_ref="fiscal_year_ending_on"
                                :value="localCompanyData?.fiscal_year_ending_on" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent 
                                type="options"
                                :options="yearOptions"  
                                theme="light" 
                                label="Last Annual Accounts on file"
                                key_ref="last_annual_account_on_file"
                                :value="localCompanyData?.last_annual_account_on_file" 
                                @inputChange="inputChangeFunc"
                            />
                            <InpComponent 
                                type="date"
                                theme="light" 
                                label="Last General Meeting of Shareholders"
                                key_ref="last_board_meeting"
                                :value="localCompanyData?.last_board_meeting" 
                                @inputChange="inputChangeFunc"
                            />
                           

                            

                        </div>
                    </div>
                    <div class="inner-section flex-1 border-rounded-gray">
                        <h3>Profit Tax</h3>
                        <div class="flex column">
                            <InpComponent 
                                type="options"
                                :options="yearOptions"                   
                                theme="light"
                                label="Last filed Profit Tax on file "
                                key_ref="last_filed_profit_tax_on_file"
                                :value="localCompanyData?.last_filed_profit_tax_on_file" 
                                @inputChange="inputChangeFunc"
                            />
                            <div class="label-like"> Extension Profit Tax</div>

                            <div class="flex row gap-10 mb-15" >
                                <div class="flex align-center justify-center">
                                    <input 
                                        type="radio" 
                                        id="YES" 
                                        style="margin: 0 5px"
                                        name="extension_profit_tax"
                                        value="YES" 
                                        :checked="localCompanyData?.extension_profit_tax == 'YES'" 
                                        @change="handleChange('extension_profit_tax', 'YES')"
                                    >
                                    <label for="YES">YES</label>
                                </div>
                                <div class="flex">
                                    <input 
                                        type="radio" 
                                        id="NO" 
                                        style="margin: 0 5px"
                                        name="extension_profit_tax"
                                        value="NO" 
                                        :checked="localCompanyData?.extension_profit_tax == 'NO'" 
                                        @change="handleChange('extension_profit_tax', 'NO')"
                                    >
                                    <label for="NO">NO</label>
                                </div>
                            </div>


                            <InpComponent 
                                v-if="localCompanyData?.extension_profit_tax == 'YES'"
                                type="date"
                                theme="light" 
                                label="Extension up till"
                                key_ref="extension_up_till"
                                :value="localCompanyData?.extension_up_till" 
                                @inputChange="inputChangeFunc"
                            />
                        </div>
                    </div>
                </div>
            </div>
    </div>
</template>

<script setup>

import InpComponent from '@/components/InpComponent.vue'
import Subsidiaries from '@/components/Subsidiaries.vue'
import DataSpanshot from '@/components/DataSpanshot.vue'

import CompanyAcounts from '@/components/CompanyAcounts.vue'
import AddressComp from '@/components/AddressComp.vue'
import { ref, defineEmits, onMounted, computed } from 'vue' 

import ChartComponent from '@/components/ChartComponent.vue'

import API from '@/api/index.js'
import store from '@/store'

const emit = defineEmits(['changeCompanyData'])

const yearOptions = computed(() => store.state.yearOptions);

const props = defineProps(['companyData','dataStructure', 'yearLabel']);
const localCompanyData = ref(props.companyData);


function changeAddress(event) {

    localAddress.value[event.property] = event.value;
    localCompanyData.value[event.property] = event.value;
    emit('changeCompanyData', localCompanyData)

}

const localAddress = ref({
    address_line_1: {
        value: props.companyData.address_line_1,
        new_value: '',
        error: ''
    },
    address_line_2: {
        value: props.companyData.address_line_2,
        new_value: '',
        error: ''
    },
    address_country: {
        value: props.companyData.address_country,
        new_value: '',
        error: ''
    },
    city: {
        value: props.companyData.city,
        new_value: '',
        error: ''
    },
    address_country: {
        value: props.companyData.address_country,
        new_value: '',
        error: ''
    },
    zip: {
        value: props.companyData.zip,
        new_value: '',
        error: ''
    },
})


function inputChangeFunc(event) {
    const event_type = event[0];
    const event_value = event[1];
    
    localCompanyData.value[event_type] = event_value;

    emit('changeCompanyData', localCompanyData)
}


function handleChange(field, event) {
    if (field === 'risk_level') {
        localCompanyData.value.risk_level = event
    } 
    localCompanyData.value[field] = event
    emit('changeCompanyData', localCompanyData)
}

</script>

<style lang="scss" scoped>

    .table-users {
        width: 100%;
        border-collapse: collapse;
        border-bottom: 1px solid #e6eaed;
    }
    .header {
        margin-bottom: 0;
    }
    .risk-section {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .sections {
        padding-top: 10px;
    }
    .kyc-fields-area {
        border: 1px solid #e0e0e0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        background-color: white;
        box-sizing: border-box;

        border-radius: 5px;
        padding: 30px;
    }

    h3 {
        text-align: left;
    }

    td {
        padding: 10px 30px;
        text-align: left;
    }
    th {
        text-align: left;
        padding: 10px 30px;
        background: #F3F5F6;
    }

    .address[class] {
        max-width: unset;
        
    }
    .border-rounded-gray {
        border: 1px solid var(--border-color);
        h3 {
            text-align: center;
            margin-top: 0;
        }
    }
    .kyc-review-date {
        width: 27%;
    }
    
</style>