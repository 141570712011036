import { createStore } from 'vuex'

export default createStore({
  state: {
    user: null,
    userAddress: null,
    userPhone: null,
    userInformation: null,
    loaded: false,
    notificationsCount: null,
    validationOn: false,
    credits_balance: null,
    response_text: null,
    notifications: [],
    subsidiaries: null,
    labelYear: null,

    objectMap: null,

    confirmationNeeded: null,
    selectedSnapshot: null,
    kycDataLoaded: false,
    dataStructureStore: null,
    companyData: null,
    companyDataInitial: null,
    commentSection: null,
    commentSectionInitial: null,
    yearOptions: [
      {value: '2021', text: '2021'},
      {value: '2022', text: '2022'},
      {value: '2023', text: '2023'},
      {value: '2024', text: '2024'},
      {value: '2025', text: '2025'},
      {value: '2026', text: '2026'},
      {value: '2027', text: '2027'},
      {value: '2028', text: '2028'},
      {value: '2029', text: '2029'},
      {value: '2030', text: '2030'},
      {value: '2031', text: '2031'},
      {value: '2032', text: '2032'},
      {value: '2033', text: '2033'},
      {value: '2034', text: '2034'},
      {value: '2036', text: '2036'},
      {value: '2037', text: '2037'},
    ],

    accounts: null,
    viewingToken: null,
    fileList: null,
    wallet: {
      loaded: false,
      address: null,
    }
  },
  getters: {
    canEditKycInfo: state => {
      return state.user && ['DATA_CONTROLLER', 'CLIENT'].includes(state.user.type);
    },
    canAddNewUsers: state => {
      return state.user && ['TRUST_OFFICER', 'DATA_CONTROLLER'].includes(state.user.type);
    },
  },
  mutations: {
    setValue(state, {key, value}) {
      state[key] = value;
    },
    addNotification(state, notification) {
      console.log('addNotification mutations')

      const id = Math.floor(Math.random() * 1000);
      state.notifications.push({...notification, id});
      setTimeout(() => {
        state.notifications = state.notifications.filter(n => n.id !== id);
      }
      , 4_000);
    }
  },
  actions: {
    setValue({ commit }, newValue) {
      commit('setValue', newValue);
    },
    addNotification({ commit }, notification) {
      commit('addNotification', notification);
    }
  },
  modules: {
  }
})
