<template>
    <div class="notification-view">
        
        <div style="min-height: 25px;" v-if="notificationsCount">
            <div 
                class="notificaiton-read" @click="markNotificationAsRead"> 
                Mark notifications as read
            </div>
        </div>
        
        <div class='notification-container'>
            <div class="notification-item" v-for="notification in notifications" :key="'notification' + notification.created" >
                <div class="icon" :class="`is-read-${ notification.is_read ? 'true' : 'false' }`">
                    <img src="@/assets/info-circle.svg"
                    style="cursor: pointer;"
                    @click="goToElement(notification)"
                    >
                </div>
                <div class="notiifcation-info-conteiner">
                    <div class="notification-time">
                        {{ renderTime(notification.created) }} by
                         <div class="user-name">
                            {{ notification.username }}
                        </div>
                    </div>
                    <div class="notification-message">
                        {{ notification.message }}
                    </div>
                    
                </div>
            </div>
        </div>
        <vue-awesome-paginate
            class="pagination"
            :total-items="notificationsCountTotal"
            :items-per-page="50"
            :max-pages-shown="10"
            v-model="currentPage"
            @click="clickCallback"
        />
    </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, defineEmits } from 'vue'

const emit = defineEmits(['updatingURL'])

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);


import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router'
import Utils from '@/utils/index.js'
import store from '../store';
import { mapState } from 'vuex';
import { VuejsPaginate } from 'vuejs-paginate';

import API from '../api/index.js'


const router = useRouter()

const notifications = ref([])
const user = computed(() => store.state.user);
const notificationsCountTotal = ref(null);
const notificationsCount = computed(() => store.state.notificationsCount);
const currentPage = ref(1);
onMounted(async() => {
    if (!user.value) {
        router.push('/login')
        return;
    }
    if (user.value.type !== 'COMPLIANCE_OFFICER') {
        router.push('/kyc')
        return;
    }
    const response = await API.getNotificationList(currentPage.value);
    await API.getNotificationCount();
    notifications.value = response.notifications
    notificationsCountTotal.value = response.count
});
async function clickCallback(pageNum) {
    currentPage.value = pageNum
    const response = await API.getNotificationList(currentPage.value);
    notifications.value = response.notifications
    notificationsCountTotal.value = response.count
}
async function markNotificationAsRead() {
    await API.markNotificationAsRead('ALL')
    notifications.value.forEach(async (notification) => {
        notification.is_read = true
    })
    API.getNotificationCount();
}
function formatDate(date) {
    return dayjs(date).fromNow();
}
function renderTime(time) {
  return dayjs(time).format('MMM DD, YYYY [at] HH:mm');
}


function selectId(element) {
    let id = ''
    let base = 'corporate'
    if (element.kyc_file) {
        id = element.kyc_file
    }
    if (element.person && !id) {
        id = element.person
    }
    if (element.entity && !id) {
        id = element.entity
    }
    if (element.mbd_related) {
        if (!id) {
            id = element.mbd_related
        }
        base = 'mbod'
    }
    if (element.ubo_related) {
        if (!id) {
            id = element.ubo_related
        }
        if (base != 'mbod') {
            base = 'ubo'
        }
    }
    return {
        base,
        id: 'id' + id
    }
    // return `?view=${base}&focus_element=id${id}`
}
async function goToElement(element) {
    await API.markNotificationAsRead(element._id)
    API.getNotificationCount();
    let info = selectId(element)
    emit('updatingURL', info)
}
</script>

<style scoped lang="scss">


.notification-view {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: white;
    margin: auto;
    padding: 20px;
    margin: 20px 40px;
    margin-top: 80px;
}
.notification-item {
    border-bottom: 1px solid var(--border-color);
    padding: 20px;
    padding-left: 30px;
    display: flex;
    align-items: center;
    gap: 20px;
}
.notification-container {
    border-radius: 10px;
    border: 1px solid var(--border-color);
    background-color: white; 
}
.user-name {
    font-weight: bold;
    color: #595b61;
}
.notification-time {
    color: #74777F;
    font-size: 12px;
    display: flex;
    gap: 5px;
}
.notification-message {
    text-align: left;
    color: #181C22;
    font-size: 16px;
}
.notiifcation-info-conteiner {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}
.time-relative {
    width: 100px;
    padding-right: 0;
}
.table-users {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid #e6eaed;
}
.is-read-false {
    background: #f9f9f9;
}
td {
    padding: 10px 30px;
    text-align: left;
}
th {
    text-align: left;
    padding: 10px 30px;
    background: #E6EAED;
}
.preview {
    padding: 10px;
    cursor: pointer;
    width: 30px;
}

.icon {
    background-color: gray;
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(224 226 236);
    position: relative;
    &.is-read-false {
        &::after {
            content: '';
            position: absolute;
            right: 10px;
            top: 10px;
            left: -15px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: rgb(224 226 236);
    
        }

    }
}

.pagination {
    display: flex;
    gap: 10px;

  &:deep(li) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

  }
  &:deep(.paginate-buttons) {
    height: 40px;

    width: 40px;
   
    border-radius: 20px;

    cursor: pointer;

    background-color: rgb(242, 242, 242);

    border: 1px solid rgb(217, 217, 217);

    color: black;
  }

  &:deep(.paginate-buttons:hover) {
    background-color: #d8d8d8;
  }

  &:deep(.active-page) {
    background-color: var(--primary-color);

    border: 1px solid var(--primary-color);

    color: white;
  }

  &:deep(.active-page:hover) {
    background-color: var(--primary-color);
  }
}

.notificaiton-read {
    width: 100%;
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
}
</style>