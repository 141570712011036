<template>
    <div class="wrapper">
        <div class="active-element">
            <div class="user-label">
                <strong>
                    <span>
                        {{ userLabel }}
                    </span>
                </strong>
            </div>
        
            <img class='arrow' src="@/assets/arrow.svg" alt="arrow" />

        </div>

        <div class="dropdown">
            <div class="top-section">
                <div class="active-element">
                    <div class="user-label">
                        <strong>
                            <span>
                                {{ userLabel }}
                            </span>
                        </strong>
                    </div>
                </div>
                <div class="full-name">
                    <span v-if="userInformation.first_name && userInformation.last_name">
                        {{ userInformation.first_name }} {{ userInformation.last_name }}
                    </span>
                    <span v-else>
                        {{ user.username }} 
                    </span>
                </div>
                <div class="email">
                    {{ user.email }}
                </div>
                <div class="email">
                    {{ getUserType(user.type) }}
                </div>
            </div>
            <router-link to="/help-center" class="dropdown-item top-border">
                <img src="@/assets/help.svg" alt="arrow" />
                <span>
                    Help Center
                </span>
            </router-link>
            <router-link to="/user-info" class="dropdown-item">
                <img src="@/assets/settings.svg" alt="arrow" />
                <span>
                    Settings
                </span>
            </router-link>
            <div class="dropdown-item top-border" @click="logout()">
                <img src="@/assets/logout.svg" alt="arrow" />
                <span>Logout</span>
            </div>
        </div>
    </div>
</template>


<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'


const store = useStore()
const router = useRouter()
const user = ref(store.state.user)
const userInformation = ref(store.state.userInformation)


const userLabel = computed(() => {
    if (userInformation.value.first_name && userInformation.value.last_name) {
        return `${userInformation.value.first_name[0]}${userInformation.value.last_name[0]}`
    } else {
        return user.value.username[0] + user.value.username[1]
    }
})

function logout() {
    store.dispatch('setValue', { key: 'user', value: null })
    localStorage.removeItem('token');
    window.location.href = window.location.href;
}

function getUserType(type) {
    const types = {
        'DATA_CONTROLLER': 'ACCOUNT MANAGER',
        'DATA_PROCESSOR': 'SUPERVISOR',
        'EXTERNAL_AUDITOR': 'EXTERNAL AUDITOR',
        'INTERNAL_AUDITOR': 'INTERNAL AUDITOR',
        'CLIENT': 'CLIENT',
    }
    return types[type];
}


</script>


<style lang="scss" scoped>



.top-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px;

}
.wrapper {
    height: 100%;
    position: relative;
    margin-right: 30px;
    display: flex;
    align-items: center;
    &:hover {
        .dropdown {
            display: flex;
        }
        .arrow {
            transform: rotateX(0deg);
        }
    }
}
.dropdown {
    display: none;
    width: 200px;
    flex-direction: column;
    position: absolute;
    align-items: center;
    top: 50px;
    right: 0px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    border: 1px solid var(--border-color);
    z-index: 100;
}
.full-name {
    font-size: 16px;
    color: black;
    font-weight: bold;
}
.email {
    font-size: 14px;
    color: gray;
    font-weight: bold;
}
.active-element {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.arrow {
    width: 20px;
    margin-left: 10px;
    transform: rotateX(180deg);
    transition: 0.3s all;
}
.user-label {
    background-color: rgb(236 240 255);
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-color);
}
a {
    text-decoration: none;
    color: black;
}
.dropdown-item {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 15px;
    box-sizing: border-box;
    gap: 10px;
    span {
        font-size: 15px;
        color: black;

       
    }
    &:hover {
        background-color: rgb(236 240 255);
    }
    &.top-border {
        border-top: 1px solid #e0e0e0;
    }
}
</style>