<template>
    <div class="sidebar">
        <div class='sidebar_item no-height' v-if="user && user.is_super_user">
            <router-link class="link-item" to="/admin">
                <div class="image-place">
                    <img src="@/assets/admin.svg" style="margin-right: -3px; margin-left: 3px;">
                </div>
                <div class="text">
                    Admin
                </div>
            </router-link>
            <div>
                <div class='left-nav'>
                    <div class='sidebar_item'>
                        <router-link class='link-item' :to="{name: 'bo_users'}"><div class="text">Users</div></router-link>
                    </div>
                    <div class='sidebar_item'>
                        <router-link class='link-item' :to="{name: 'dashboard'}"><div class="text">Dashboard</div></router-link>
                    </div>
                    <div class='sidebar_item'>
                        <router-link class='link-item' :to="{name: 'platform_control'}"><div class="text">Platform Control</div></router-link>
                    </div>
                    <div class='sidebar_item'>
                        <router-link class='link-item' :to="{name: 'terms-admin'}"> <div class="text">Terms</div></router-link>
                    </div>
                </div>
            </div>
        </div>
        <hr v-if="user && user.is_super_user">

        <div class='sidebar_item'>
            <router-link class="link-item" to="/kyc">
                <div class="image-place">
                    <img src="@/assets/kyc.svg">
                </div>
                <div class="text">Compliance</div>
            </router-link> 
        </div>
        <div class='sidebar_item' v-if="!['CLIENT'].includes(user.type)">
            <router-link class="link-item" to="/subsidiary">
                <div class="image-place">
                    <img src="@/assets/subsidiaries.svg">
                </div>
                <div class="text">Subsidiaries</div>
            </router-link> 
        </div>
        
        <div class='sidebar_item' v-if="!['CLIENT'].includes(user.type)">
            <router-link class="link-item" to="/document-history">
                <div class="image-place">
                    <!-- <img src="@/assets/kyc.svg"> -->
                </div>
                <div class="text">Review History</div>
            </router-link> 
        </div>
        <hr>
        <div v-if="!['CLIENT'].includes(user.type)">
            <router-link class="link-item" to="/user-management">
                <div class="image-place">
                    <img src="@/assets/person.svg">
                </div>
                <div class="text">User Management</div>
            </router-link> 
        </div>

            <!-- <div class='sidebar_item' v-if="user && user.type != 'DATA_CONTROLLER'">
                <router-link class="link-item" to="/user-info">
                    <div class="image-place">
                        <img src="@/assets/user-info.svg">


                    </div>
                    <div class="text">User Information</div>
                </router-link> 
            </div> -->

            <!-- <div class='sidebar_item' v-if="user && user.type != 'DATA_CONTROLLER'">
                <router-link class="link-item" to="/convert">
                    <div class="image-place">
                        <img src="@/assets/convert.svg" style="margin-left:-3px;">

                    </div>
                    <div class="text">Convert</div>
                </router-link> 
            </div>
            <div class='sidebar_item' v-if="user && user.type != 'DATA_CONTROLLER'">
                <router-link class="link-item" to="/file-list">
                    <div class="image-place"></div>
                    <div class="text">Uploads history</div>
                </router-link> 
            </div> -->
            <!-- <div class='sidebar_item' v-if="user && user.type != 'DATA_CONTROLLER'">
                <router-link class="link-item" to="/saved-info">
                    <div class="image-place"></div>
                    <div class="text">Saved Info</div>
                </router-link> 
            </div> -->
            <!-- <div class='sidebar_item'>
                <span class="link-item" v-if="user && user.type != 'DATA_CONTROLLER'" @click="downloadSample()">
                    <div class="image-place"></div>
                    <div class="text">Download Sample</div>
                </span> 
            </div> -->

        <div class='sidebar_item' v-if="user.type == 'TRUST_OFFICER' || user.is_super_user">
            <router-link class="link-item" to="/wallet">
                <div class="image-place">
                    <img src="@/assets/wallet.svg" style="margin-left: -4px;">
                </div>
                <div class="text">Wallet</div>
            </router-link> 
        </div>
        <div class='sidebar_item'>
            <router-link class="link-item" to="/terms-and-conditions">
                <div class="image-place">
                </div>
                <div class="text">Terms and Conditions</div>
            </router-link> 
        </div>
    </div>
</template>

<script setup>

import { useStore } from 'vuex';
import { useRouter } from 'vue-router'
import API from '../api/index.js'


const store = useStore();
const user = store.state.user;


function downloadSample() {
    API.downloadFile('sample', {name: 'sample.xlsx'})
}

</script>

<style scoped lang="scss">
    .sidebar {
        padding: 20px;
        background-color: white;
        width: var(--sidebar-width);
        position: fixed;
        top: 60px;
        box-sizing: border-box;
        bottom: 0;
        left: 0;
        border-right: 0.5px solid var(--border-color);
        z-index: 100;
    }
    .sidebar_item:not(.no-height) {
        height: 40px;
    }
    .text {
        color: rgb(116 119 127);
        font-size: 14px;
    }
    .image-place {
        width: 25px;
        height: 25px;
        padding-bottom: 3px;

        img {
            width: 100%;
            height: 100%;
        }
    }
    
    a.link-item, .link-item {
        height: 40px;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: bold;
        color: var(--sidebar-text-color);
        border-radius: 1.5rem;
        padding-left: 10px;
        &.router-link-exact-active {
            .text {
                color: var(--primary-color); 
            }
            position: relative;
            background-color: rgb(236 240 255);
        }
    }

  

</style>